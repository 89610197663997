import React from 'react';


export default function index() {
  return (
    <div>
       
      hello assessor
    </div>
  )
}
