import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { COLORS } from '../Component/colors';
import { CorporateFare as CorporateFareIcon, CardMembership as CardMembershipIcon, LocationOn, Email, Phone, AccountCircle, Lock, DateRange, FormatListNumbered, Person } from '@mui/icons-material';
import axios from 'axios';
import api from '../Services/api';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';





const useStyles = makeStyles({
  card: {
    margin: 50,
    padding: 20
  },

  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    marginBottom: 15,
  },
  icon: {
    marginRight: 10,
  },
  textField: {
    width: '100%',
  },
  selectField: {
    width: '100%',
  },

});


const CompanyCard = () => {
  let data1 = sessionStorage.getItem('user');
  let assessorData = JSON.parse(data1);
  let id1 = assessorData.id;
  let id2 = assessorData.companyId;
  const classes = useStyles();
  const [formData, setFormData] = useState({
    clientName: '',
    assessorId: id1,
    principleEmployer: '',
    companyId: id2,
    companyAddress: '',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    stateId: '',
    placeName: '',
    natureOfWork: '',
    vendorCompanyName: '',
    vendorName: '',
    isVendor: true,
    isDeleted: false,
    vendorCompanyAddress: '',
    vendorEmail: '',
    vendorPhoneNumber: '',
    vendorUsername: '',
    vendorPassword: '',
    vendorContractStartDate: '',
    vendorContractEndDate: '',
  });
  const [errors, setErrors] = useState({});
  const [clientOptions, setClientOptions] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);




  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  //FETCHING LOGGED ASSESSOR CLIENT DETAILS 
  useEffect(() => {
    api.assessor.getClient(id2).then(response => {
      const client = response.data.data;
      setFormData(prevState => ({
        ...prevState,
        principleEmployer: client.principleEmployer || '',
        companyAddress: client.companyAddress || '',
        placeName: client.placeName || ' ',
        stateId: client.stateId || '',
        clientName: client.clientName || '',
        clientContactPersonName: client.clientContactPersonName || '',
        clientContactPersonPhoneNumber: client.clientContactPersonPhoneNumber || '',
        clientContactPersonEmailId: client.clientContactPersonEmailId || '',
      }));
      setClientOptions(response.data.data);
    })
      .catch(error => {
        console.error('Error fetching company names:', error);
      });
  }, []);



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

  };


  //VALIDATION
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;
    if (!formData.clientName) newErrors.clientName = 'Select client';
    if (!formData.natureOfWork) newErrors.natureOfWork = 'Nature of work is required';
    if (!formData.vendorCompanyName) newErrors.vendorCompanyName = 'Vendor Company Name is required';
    if (!formData.vendorName) newErrors.vendorName = 'Vendor Name is required';
    if (!formData.vendorCompanyAddress) newErrors.vendorCompanyAddress = 'Vendor Company Address is required';
    if (!emailPattern.test(formData.vendorEmail)) {
      newErrors.vendorEmail = formData.vendorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(formData.vendorPhoneNumber)) {
      newErrors.vendorPhoneNumber = formData.vendorPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    if (!formData.vendorUsername) newErrors.vendorUsername = 'Username is required';
    if (!formData.vendorPassword) newErrors.vendorPassword = 'Password is required';
    if (!formData.vendorContractStartDate) newErrors.vendorContractStartDate = 'Contract Start Date is required';
    if (!formData.vendorContractEndDate) newErrors.vendorContractEndDate = 'Contract End Date is required';
    const selectField = formData.clientName || formData.clientName;
    if (!selectField) {
      newErrors.selectField = 'Please select client';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  //CREATING VENDOR PROFILE
  const handleSave = () => {
    if (validateForm()) {
      axios.post('https://www.grcpulse.in/api/vendorMasterProfile/newVendorProfile', formData)
        .then(response => {
          if (response.status === 201) {
            const vendorData = response.data;
            sessionStorage.setItem('vendor', JSON.stringify(vendorData));
            window.alert("Vendor Created Successfully");
            handleSubmit();

          }
          else if (response.status === 400) {
            window.alert("The username you Entered is already in use. Please enter a different one")
          }


        })
        .catch(error => {
          console.error('Error saving vendor profile:', error);
        });
    }
  };


  //VENDOR MASTERDATA CREATION
  const handleSubmit = () => {
    let data = sessionStorage.getItem('vendor');
    let vendorData = JSON.parse(data);
    const id = vendorData.data.vendorId;

    const masterData = {
      vendorId: id,
      assessorId: id1,

      dateOfCommencement: {
        value: ""
      },
      dateOfCompletion: {
        value: ""
      },
      poCopy: {
        value: ""
      },
      epfoAllotment: {
        value: ""
      },
      esicAllotment: {
        value: ""
      },
      pTaxRc: {
        value: ""
      },
      ptEc: {
        value: ""
      },
      lwf: {
        value: ""
      },
      factoryLicence: {
        value: ""
      },
      buildingPlanLayout: {
        value: ""
      },
      stabilityCertificate: {
        value: ""
      },
      concernToEstablishment: {
        value: ""
      },
      concernToOperations: {
        value: ""
      },
      fireAdvisory: {
        value: ""
      },
      fireNoc: {
        value: ""
      },
      environmentClearance: {
        value: ""
      },
      medicalOrHospitalCertificate: {
        value: ""
      },
      apprenticeshipRegistration: {
        value: ""
      },
      neemOrNapsRegistration: {
        value: ""
      },
      clraRc: {
        value: ""
      },
      sAndCeRc: {
        value: ""
      },
      clraLicence: {
        value: ""
      },
      bocwRc: {
        value: ""
      },
      ismwRc: {
        value: ""
      },
      ismwLicence: {
        value: ""
      },
      passaraLicence: {
        value: ""
      },
      fssaiLicence: {
        value: ""
      },


    }
    axios.post(`https://www.grcpulse.in/api/companyMasterData/newMasterData`, masterData)
      .then(response => {
        if (response.data.message === 'Masterdata Created Successfully') {
          window.alert("VendorMasterData Created Successfully");
          setFormData({
            natureOfWork: '',
            vendorCompanyName: '',
            vendorName: '',
            vendorCompanyAddress: '',
            vendorEmail: '',
            vendorPhoneNumber: '',
            vendorUsername: '',
            vendorPassword: '',
            vendorContractStartDate: '',
            vendorContractEndDate: '',
          });


        }


      })
      .catch(error => {
        console.error('Error saving company master data:', error);
      });
  };



  return (
    <div>

      <Card className={classes.card}>
        <Typography className="text-center  text-dark p-3 mb-4" variant="h5" style={{ backgroundColor: COLORS.bggray }} >
          Create Vendor
        </Typography>
        <CardContent>
          <Typography className="text-center mb-4 " color="textSecondary" gutterBottom>
            - - - - - - - - - <span className="text-dark"> Client Details </span> - - - - - - - - - - -
          </Typography>

          <div className={classes.detail}>
            <CorporateFareIcon className={classes.icon} />
            <TextField className={classes.textField} label="Principal Employer" name="principleEmployer" value={formData.principleEmployer} onChange={handleChange} InputProps={{ readOnly: true }} />

          </div>

          <div className={classes.detail}>
            <LocationOn className={classes.icon} />
            <TextField className={classes.textField} label="Address" name="companyAddress" value={formData.companyAddress} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
          <div className={classes.detail}>
            <LocationOn className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Place"
              name="placeName"
              value={formData.placeName}
              onChange={handleChange}

              InputProps={{ readOnly: true }}

            />
          </div>
          <div className={classes.detail}>
            <CorporateFareIcon className={classes.icon} />
            <TextField className={classes.textField} label="Contractor" name="clientName " value={formData.clientName} onChange={handleChange} InputProps={{ readOnly: true }} />

          </div>

          <div className={classes.detail}>
            <Person className={classes.icon} />
            <TextField className={classes.textField} label="Contact Person Name" name="clientContactPersonName" value={formData.clientContactPersonName} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
          <div className={classes.detail}>
            <Email className={classes.icon} />
            <TextField className={classes.textField} label="Email" name="clientContactPersonEmailId" value={formData.clientContactPersonEmailId} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
          <div className={classes.detail}>
            <Phone className={classes.icon} />
            <TextField className={classes.textField} label="Phone Number" name="clientContactPersonPhoneNumber" value={formData.clientContactPersonPhoneNumber} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>


          <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
            - - - - - - - - - <span className="text-dark">Vendor Details</span> - - - - - - - - - - -
          </Typography>
          <div className={classes.detail}>
            <Person className={classes.icon} />
            <TextField className={classes.textField} label="Vendor Company Name" name="vendorCompanyName" value={formData.vendorCompanyName} onChange={handleChange} error={!!errors.vendorCompanyName}
              helperText={errors.vendorCompanyName} />
          </div>
          <div className={classes.detail}>
            <LocationOn className={classes.icon} />
            <TextField className={classes.textField} label="Vendor Company Address" name="vendorCompanyAddress" value={formData.vendorCompanyAddress} onChange={handleChange} error={!!errors.vendorCompanyAddress}
              helperText={errors.vendorCompanyAddress} />
          </div>
          <div className={classes.detail}>
            <Person className={classes.icon} />
            <TextField className={classes.textField} label="Vendor Name" name="vendorName" value={formData.vendorName} onChange={handleChange} error={!!errors.vendorName}
              helperText={errors.vendorName} />
          </div>
          <div className={classes.detail}>
            <Phone className={classes.icon} />
            <TextField className={classes.textField} label="Vendor Phone Number" name="vendorPhoneNumber" value={formData.vendorPhoneNumber} onChange={handleChange} error={!!errors.vendorPhoneNumber}
              helperText={errors.vendorPhoneNumber} />
          </div>
          <div className={classes.detail}>
            <Email className={classes.icon} />
            <TextField className={classes.textField} label="Vendor Email" name="vendorEmail" value={formData.vendorEmail} onChange={handleChange} error={!!errors.vendorEmail}
              helperText={errors.vendorEmail} />
          </div>
          <div className={classes.detail}>
            <FormatListNumbered className={classes.icon} />
            <TextField className={classes.textField} label="Nature Of Work" name="natureOfWork" value={formData.natureOfWork} onChange={handleChange} error={!!errors.natureOfWork}
              helperText={errors.natureOfWork} />
          </div>

          <div className={classes.detail}>
            <DateRange className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Contract Start Date"
              type="date"
              name="vendorContractStartDate"
              value={formData.vendorContractStartDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.vendorContractStartDate}
              helperText={errors.vendorContractStartDate}
            />
          </div>
          <div className={classes.detail}>
            <DateRange className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Contract End Date"
              type="date"
              name="vendorContractEndDate"
              value={formData.vendorContractEndDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.vendorContractEndDate}
              helperText={errors.vendorContractEndDate}
            />
          </div>
          <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
            - - - - - - - - - - - <span className="text-dark"> Login Details </span> - - - - - - - - - - -
          </Typography>

          <div className={classes.detail}>
            <AccountCircle className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Username"
              name="vendorUsername"
              value={formData.vendorUsername}
              onChange={handleChange}
              style={{ width: '100%' }}
              error={!!errors.vendorUsername}
              helperText={errors.vendorUsername}
            />
          </div>

          <div className={classes.detail}>
            <Lock className={classes.icon} />
            <FormControl sx={{ width: '100%' }} variant="outlined">

              <TextField
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

                label="Password"

                name="vendorPassword"
                value={formData.vendorPassword}
                onChange={handleChange}
                error={!!errors.vendorPassword}
                helperText={errors.vendorPassword}
              />
            </FormControl>

          </div>

          <button className="btn btn-dark  text-white w-100 mt-4 " disabled={!validateForm} onClick={handleSave}>Save</button>

        </CardContent>
      </Card>
    </div>
  );
};

export default CompanyCard;
