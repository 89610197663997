import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import api from '../Services/api';
import { COLORS } from '../Component/colors';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement, 
  Tooltip,
  Legend
} from 'chart.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Register necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);



export const VendorList = () => {
  const [vendors, setVendors] = useState([]);

  const [expandedVendor, setExpandedVendor] = useState(null);
  const [vendorChartData, setVendorChartData] = useState({});
  const [openDialog, setOpenDialog] = useState(false); // State to manage Dialog visibility
  const [formData, setFormData] = useState({
    vendorId: '',
    vendorCompanyName: '',
    vendorCompanyAddress: '',
    vendorName: '',
    vendorEmail: '',
    vendorPhoneNumber: '',
    vendorContractStartDate: '',
    vendorContractEndDate: '',
    natureOfWork: ''
  });

  let data = sessionStorage.getItem('user');
    let assessorData = JSON.parse(data);
    let id = assessorData.id;

  const fetchData = async () => {
    

    try {
      const response = await api.assessor.getVendors(id);
      setVendors(response.data.data);
      const chartDataPromises = response.data.data.map((vendor) => {
        return masterData(vendor.vendorId);
      });

    
      await Promise.all(chartDataPromises);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    
  }, [id]);
  const masterData = async (vendorId) => {
    try {
      const response = await axios.get(`https://www.grcpulse.in/api/companyMasterData/vendorDetails/${vendorId}`);
      const chartData = response.data; // assuming this has the necessary data

      setVendorChartData((prevData) => ({
        ...prevData,
        [vendorId]: chartData // Store chart data for this vendor by vendorId
      }));
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  // Prepare chart data for the pie chart
  const getChartData = (vendorId) => {
    if (!vendorChartData[vendorId]) return {}; // Return empty object if no data for the vendor
    
    const chart = vendorChartData[vendorId];
    const accepted = chart.acceptPercentage || 0;
    const rejected = chart.rejectPercentage || 0;
    const underReview = chart.URpercent || 0;
    const total = chart.totalPercantage || 0

    return {
      labels: ['Accepted', 'Rejected', 'Under Review'],
      datasets: [
        {
          data: [accepted, rejected, underReview],
          backgroundColor: [COLORS.success, COLORS.danger, COLORS.bggray]
        },
      ],
    };
  };
  const handleEditClick = (vendor) => {
    setFormData({
      vendorId: vendor.vendorId,
      vendorCompanyName: vendor.vendorCompanyName,
      vendorCompanyAddress: vendor.vendorCompanyAddress,
      vendorName: vendor.vendorName,
      vendorEmail: vendor.vendorEmail,
      vendorPhoneNumber: vendor.vendorPhoneNumber,
      vendorContractStartDate: vendor.vendorContractStartDate,
      vendorContractEndDate: vendor.vendorContractEndDate,
      natureOfWork: vendor.natureOfWork
    });
    setOpenDialog(true); 
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleExpandClick = (vendorId) => {
    setExpandedVendor(expandedVendor === vendorId ? null : vendorId);
  };
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            return `${value}%`; // Add the percentage symbol
          },
        },
      },
      legend: {
        labels: {
          generateLabels: function (chart) {
            const data = chart.data.datasets[0].data;
            const labels = chart.data.labels;
            return labels.map((label, index) => {
              return {
                text: `${label}: ${data[index]}%`, 
                fillStyle: chart.data.datasets[0].backgroundColor[index],
              };
            });
          },
        },
      },
    },
  };
  
  

  const handleMonthlyData=(id)=>{
    let data = sessionStorage.getItem('user');
   let assessorData = JSON.parse(data);
   const id1=assessorData.id;
   const id2=assessorData.companyId;
  
   
  const Monthlydata ={
    vendorId:id,
    assessorId:id1,
     companyId:id2,
     complianceFiledDate: '',
     auditorObservation: '',
                    auditorRecommendation: '',
                    objectivesOfTheAudit: '',
    
      inAndOutTimeRegister: {
         
          fileUrl: "",
          validation: "",
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        combinedMusterRollCumRegisterOfWages: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        RegisterOfWorkmen: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        BankStatementOrSalaryCreditConfirmation: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        PaymentDoneAsPerRevisedRateOfMinimumwages: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        MonthlyBonusPaidOrNot: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        WageSlip: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        ServiceCertificateForLeftEmployees: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        ESIAccidentRegister: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        ESICContributionChallan: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        ESICMonthlyStatement: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        PFMonthlyECR: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        PFChallan: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        PFTRRNSummary: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        PFDeclarationOnLetterhead: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        ESIDeclarationOnLetterhead: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        RegisterOfEqualRemuneration: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        OvertimeDeclarationOnLetterhead: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        professionalTaxReturns: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        PTChallan: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        LWFChallan: {
          // na: Boolean,
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
        statutoryBonusReturn: {
          
          fileUrl: "",
          validation: "",
          vendorRemark: "",
          remark: "",
          qId: "",
        },
  }
 axios.post(`https://www.grcpulse.in/api/monthlydata/create-monthly`,Monthlydata)
  .then(response=>{
    if(response.data.message === 'Monthly Data Created Successfullt'){
      window.alert("Monthly Data Created Successfullt");
      
      
    }
    else if(response.status === 404){
window.alert("Monthly Data already exists")
    }
    
  })
}

  const handleSave = async () => {
    try {
      const response = await api.assessor.editVendor(formData.vendorId, formData);
      if (response.status === 200) {
        alert("Updated successfully!");
        fetchData();
        setOpenDialog(false); 
      } else {
        alert("Error saving data");
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };
  
  const handleCancel = () => {
    setOpenDialog(false);
  };

const handleDeleteClick = async (vendor) => {
  const isConfirmed = window.confirm(`Are you sure you want to delete ${vendor.vendorCompanyName}?`);
  if(isConfirmed){
  try{
    const vendorId = vendor.vendorId;
    console.log("vendorId",vendorId);
const response = await api.vendor.deleteVendors(vendorId);
if(response.status===200){
  window.alert("Deleted succesfully")
}
else{
  window.alert("some error occured please try again later")
}
console.log(response);
  }catch(error){
    console.error('Error Finding the Id',error)
  }
  }
else{
  console.log("cancelled");
}

}
  return (
    <div className="m-3">
      <h5 className='mt-4'>Vendor List <i class="bi bi-arrow-right-square-fill"></i></h5>
   
        <TableContainer className="mt-3" component={Paper}>
          <table   >
            <thead style={{backgroundColor:'#f7f4f4'}}>
              <tr >
                <th  style={{color:'black',fontWeight:"bold",letterSpacing:'0.5px'}}>Client Details</th>
                <th style={{color:'black',fontWeight:"bold",letterSpacing:'0.5px'}}>Vendor Company Details</th>
                <th  style={{color:'black',fontWeight:"bold",letterSpacing:'0.5px'}}>Nature Of Work</th>
                <th style={{color:'black',fontWeight:"bold",letterSpacing:'0.5px'}}>Contact-Person Details</th>
                {/* <th className="text-center" style={{color:'white',fontWeight:"bold",letterSpacing:'0.5px'}}>Contract</th> */}
                <th style={{color:'black',fontWeight:"bold",letterSpacing:'0.5px'}}>Status</th>
                <th  style={{color:'black',fontWeight:"bold",letterSpacing:'0.5px'}}>Actions</th>
              </tr>
            </thead>
            <TableBody>
              {vendors.map((vendor) => (
                <TableRow key={vendor.vendorId}>
                                  <TableCell>
                    <Accordion
                      expanded={expandedVendor === vendor.vendorId}
                      onChange={() => handleExpandClick(vendor.vendorId)}
                      sx={{
                        padding: '0',
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': { display: 'none' },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${vendor.vendorId}-content`}
                        id={`panel-${vendor.vendorId}-header`}
                        sx={{
                          backgroundColor: '#f4f4f4',
                          borderBottom: '1px solid #ddd',
                          padding: '8px 16px',
                        }}
                      >
                        <Typography variant="body1">{vendor.principleEmployer}</Typography>
                      </AccordionSummary>

                      <AccordionDetails sx={{ padding: '16px 24px' }}>
                        {expandedVendor === vendor.vendorId && (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="body1">Principal Employer: {vendor.principleEmployer}</Typography>
                              <Typography variant="body1">State : {vendor.stateId}</Typography>
                              <Typography variant="body1">Place : {vendor.placeName}</Typography>
                              <Typography variant="body1">Contractor : {vendor.clientName}</Typography>
                              <Typography variant="body1">Contact Person : {vendor.clientContactPersonName}</Typography>
                              <Typography variant="body1">Phone : {vendor.clientContactPersonPhoneNumber}</Typography>
                              <Typography variant="body1">Email : {vendor.clientContactPersonEmailId}</Typography>
                            </Grid>
                          </Grid>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>

                  <TableCell>{vendor.vendorCompanyName} <br></br>{vendor.vendorCompanyAddress}</TableCell>
                  <TableCell>{vendor.natureOfWork}</TableCell>
                  <TableCell>{vendor.vendorName}<br></br> {vendor.vendorPhoneNumber} <br></br>{vendor.vendorEmail}</TableCell>
                  {/* <TableCell>{vendor.vendorContractStartDate} - {vendor.vendorContractEndDate}</TableCell> */}
                  <TableCell>
                    {vendorChartData[vendor.vendorId] && vendorChartData[vendor.vendorId].acceptPercentage !== undefined && (
                      <Pie data={getChartData(vendor.vendorId)} options={options}/>
                    )}
                  </TableCell>
                  <TableCell>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <button className="mx-1 btn btn-sm btn-primary" onClick={() => handleEditClick(vendor)}>Edit</button>

                    <button className=" mx-1 btn btn-sm btn-danger" onClick={() => handleDeleteClick(vendor)}>Delete</button>
                    <button className=" mx-1 btn btn-sm btn-warning" disabled={vendorChartData[vendor.vendorId]?.acceptPercentage < 85} onClick={()=>handleMonthlyData(vendor.vendorId)}>Create</button>
                    </div>
                  </TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </table>
        </TableContainer>
     

      {/* Dialog for Editing Vendor Details */}
      <Dialog open={openDialog} onClose={handleCancel} maxWidth="md" fullWidth>
        <h6 style={{textAlign:'center',color:'white',backgroundColor:"#1C1C1C",padding:'24px',letterSpacing:'0.5px'}}>EDIT VENDOR DETAILS</h6>
        <DialogContent >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Company Name"
                name="vendorCompanyName"
                value={formData.vendorCompanyName}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Company Address"
                name="vendorCompanyAddress"
                value={formData.vendorCompanyAddress}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Vendor Name"
                name="vendorName"
                value={formData.vendorName}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="vendorEmail"
                value={formData.vendorEmail}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="vendorPhoneNumber"
                value={formData.vendorPhoneNumber}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nature of Work"
                name="natureOfWork"
                value={formData.natureOfWork}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              
            <TextField
                fullWidth
                label="Contract Start Date"
                 type="date"
                name="vendorContractStartDate"
                value={formData.vendorContractStartDate ? formData.vendorContractStartDate.slice(0, 10) : ''}
                onChange={handleFormChange}
                
                InputLabelProps={{ shrink: true }}
              />
            
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField
                fullWidth
                label="Contract End Date"
                name="vendorContractEndDate"
                type="date"
                value={formData.vendorContractEndDate ? formData.vendorContractEndDate.slice(0, 10): ''}
                onChange={handleFormChange}
                
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
       
          <div className='mx-4 mb-3' style={{display:'flex',justifyContent:'space-between'}}>
          <button className="btn btn-md btn-danger" onClick={handleCancel} >
            Cancel
          </button>
          <button className="btn btn-md btn-success" onClick={handleSave} >
            Save
          </button>
          </div>
      
      </Dialog>
    </div>
  );
};
