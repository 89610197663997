import React, { useState, useRef, useEffect } from 'react';
import { Form, Card, Button, Row, Col } from 'react-bootstrap';
import { BiUpload } from 'react-icons/bi';
import VendorAppBar from './VendorAppBar';
import { COLORS } from '../Component/colors';
import axios from 'axios';
import './EditableCard.css';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';







const titles = [
  "Date of Commencement",
  "Date of Completion",
  "PO Copy Number",
  "EPFO Allotment copy",
  "ESIC allotment copy",
  "P Tax RC Number",
  "PT EC Number",
  "LWF Number",
  "Factory Licence",
  "Building plan layout",
  "Stability Certificate",
  "Consent to Establishment",
  "Consent to Operations",
  "Fire Advisory",
  "Fire NOC",
  "Environment Clearance",
  "Medical/Hospital - Certificate",
  "Apprenticeship Registration",
  "NEEM/NAPS - Registration",
  "CLRA RC",
  "S&CE RC",
  "CLRA Licence",
  "BOCW RC",
  "ISMW RC",
  "ISMW Licence",
  "PSARA Licence",
  "FSSAI Licence"
];





const MasterData = () => {
  const initialState = titles.map(title => ({ value: '', fileUrl: '', validation: 0, remark: '', na: false, qId: '', editing: false }));
  const [cardStates, setCardStates] = useState(initialState);
  const fileInputRefs = useRef(Array(titles.length).fill(null).map(() => React.createRef()));
  const [userId, setUserId] = useState('');
  const[userType,setUserType]= useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [countValidation1, setCountValidation1] = useState(0);
  const [countValidation2, setCountValidation2] = useState(0);
  const [countValidation3, setCountValidation3] = useState(0);
  const[snackbarErrorMessage,setSnackbarErrorMessage]=useState('');
  const [uploadStatus, setUploadStatus] = useState(Array(titles.length).fill('initial'));
  const [initialCardStates, setInitialCardStates] = useState(initialState);







  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('user'));

    if (userData && userData.id) {
      setUserId(userData.id);
      setUserType(userData.type);

    }
  }, []);

  useEffect(() => {
    console.log(userId);
    console.log(userType);
    if (userType === 'client') {
        
        fetchCompanyData(userId);
    }
    else if(userType === 'vendor'){
      fetchData(userId);
    }
}, [userId]);


  

  const fetchData = async (userId) => {
    try {
      const response = await axios.get(`https://www.grcpulse.in/api/companyMasterData/vendorDetails/${userId}`);
      const responseData = response.data.data;
      let validation1 = 0;
      let validation2 = 0;
      let validation3 = 0;// Access the nested data object
      const updatedCardStates = Object.keys(responseData).map(key => {
        let background = COLORS.light;
        const validation = responseData[key].validation;
        if (validation === 1) {
          background = COLORS.bggray;
          validation1 += 1;

        } else if (validation === 2) {
          background = COLORS.success;
          validation2 += 1;

        } else if (validation === 3) {
          background = COLORS.danger;
         validation3 += 1;

        }

        return {
          value: responseData[key].value,
          fileUrl: responseData[key].fileUrl,
          validation: responseData[key].validation,
          remark: responseData[key].remark,
          na: responseData[key].na,
          editing: responseData[key].editing,
          qId: responseData[key].qId,
          background: background 
        };
      });
      setCardStates(updatedCardStates);
      setInitialCardStates(updatedCardStates);
      setCountValidation1(validation1);
      setCountValidation2(validation2);
      setCountValidation3(validation3);



    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };
  const fetchCompanyData = async (userId) => {
    try {
      const response = await axios.get(`https://www.grcpulse.in/api/companyMasterData/clientMasterData/${userId}`);
      const responseData = response.data.data;
      let validation1 = 0;
      let validation2 = 0;
      let validation3 = 0;// Access the nested data object
      const updatedCardStates = Object.keys(responseData).map(key => {
        let background = COLORS.light;
        const validation = responseData[key].validation;
        if (validation === 1) {
          background = COLORS.bggray;
          validation1 += 1;

        } else if (validation === 2) {
          background = COLORS.success;
          validation2 += 1;

        } else if (validation === 3) {
          background = COLORS.danger;
         validation3 += 1;

        }

        return {
          value: responseData[key].value,
          fileUrl: responseData[key].fileUrl,
          validation: responseData[key].validation,
          remark: responseData[key].remark,
          na: responseData[key].na,
          editing: responseData[key].editing,
          qId: responseData[key].qId,
          background: background 
        };
      });
      setCardStates(updatedCardStates);
      setInitialCardStates(updatedCardStates);
      setCountValidation1(validation1);
      setCountValidation2(validation2);
      setCountValidation3(validation3);



    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };









  const handleFileChange = (e, index) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const updatedCardStates = [...cardStates];
      updatedCardStates[index].fileUrl = selectedFile.name;
      const isFilled = updatedCardStates[index].value !== '' && updatedCardStates[index].fileUrl !== '';
      updatedCardStates[index].isFilled = isFilled;
      setCardStates(updatedCardStates);
      const updatedUploadStatus = [...uploadStatus];
        updatedUploadStatus[index] = 'initial';
        setUploadStatus(updatedUploadStatus);
    }
  };


  const handleUploadClick = (index) => {
    fileInputRefs.current[index].current.click();
  };
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].value = value;
    const isFilled = updatedCardStates[index].value !== '' && updatedCardStates[index].fileUrl !== '';
    updatedCardStates[index].isFilled = isFilled;
    setCardStates(updatedCardStates);
  };

  const handleEditClick = (index) => {
    const updatedCardStates = [...cardStates];
    // Save initial state before editing starts
    setInitialCardStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = { ...cardStates[index] };
      return newStates;
    });
    updatedCardStates[index].editing = true;
    setCardStates(updatedCardStates);
  };

  const handleNotApplicableChange = (index) => {
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].na = !updatedCardStates[index].na;
    if (updatedCardStates[index].na) {
      updatedCardStates[index].value = '';
      updatedCardStates[index].fileUrl = '';

    }
    setCardStates(updatedCardStates);
  };




  const handlesave = (index) => {

    const updatedValue = cardStates[index].value;
    const updatedFileUrl = cardStates[index].fileUrl;
    const updatedValidation = 1;
    const updatedRemark = cardStates[index].remark;
    const updatena = cardStates[index].na;
    const masterdata = {
      [userType === 'client' ? 'companyId' : 'vendorId']: userId
    };


    if (index === 0) {
      masterdata.dateOfCommencement = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 1) {
      masterdata.dateOfCompletion = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };

    }

    if (index === 2) {
      masterdata.poCopy = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 3) {
      masterdata.epfoAllotment = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 4) {
      masterdata.esicAllotment = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 5) {
      masterdata.pTaxRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 6) {
      masterdata.ptEc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 7) {
      masterdata.lwf = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 8) {
      masterdata.factoryLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 9) {
      masterdata.buildingPlanLayout = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 10) {
      masterdata.stabilityCertificate = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 11) {
      masterdata.concernToEstablishment = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 12) {
      masterdata.concernToOperations = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 13) {
      masterdata.fireAdvisory = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 14) {
      masterdata.fireNoc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 15) {
      masterdata.environmentClearance = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 16) {
      masterdata.medicalOrHospitalCertificate = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 17) {
      masterdata.apprenticeshipRegistration = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 18) {
      masterdata.neemOrNapsRegistration = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 19) {
      masterdata.clraRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 20) {
      masterdata.sAndCeRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 21) {
      masterdata.clraLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 22) {
      masterdata.bocwRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 23) {
      masterdata.ismwRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 24) {
      masterdata.ismwLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }
    if (index === 25) {
      masterdata.passaraLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    } if (index === 26) {
      masterdata.fssaiLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        na: updatena,
      };
    }


    axios.put('https://www.grcpulse.in/api/companyMasterData/updateMasterData', masterdata)
      .then(response => {
        console.log("Masterdata updated successfully:", response);
        if (response.data.message === "Masterdata Updated") {
          for (const key in masterdata) {

            if (masterdata.hasOwnProperty(key)) {

              const updatedCardStates = [...cardStates];
              updatedCardStates[index].editing = false;
              updatedCardStates[index].background = COLORS.bggray;
              updatedCardStates[index].validation = 1;
              setCardStates(updatedCardStates);

            }
          }

        }
      })

      .catch(error => {
        console.error("Error updating masterdata:", error);

      });
  };


  const handleFileUpload = async (index, title) => {
    const selectedFile = fileInputRefs.current[index].current.files[0];
    console.log("title", title);
    console.log("index", index);
    if (!selectedFile) {
      console.error("No file selected.");
      setSnackbarErrorMessage('No file selected!');
      setErrorSnackbarOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('vendorId', userId);
    formData.append('fieldName', title);

    const updatedUploadStatus = [...uploadStatus];
    updatedUploadStatus[index] = 'uploading';
    setUploadStatus(updatedUploadStatus);
    try {
      const response = await axios.put('https://www.grcpulse.in/api/companyMasterData/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('File uploaded successfully:', response.data);
      const updatedCardStates = [...cardStates];
      updatedCardStates[index].fileUrl = response.data.data;
      setCardStates(updatedCardStates);
      updatedUploadStatus[index] = 'uploaded';
      setUploadStatus(updatedUploadStatus);
      setSnackbarMessage('File uploaded successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      
      console.error('Error uploading file:', error);
      setSnackbarErrorMessage('File upload failed!');
      setErrorSnackbarOpen(true);
      updatedUploadStatus[index] = 'initial';
        setUploadStatus(updatedUploadStatus);
    } 
      
  };
  // const handleViewDocument = (fileUrl) => {
  //   window.open(fileUrl, '_blank');
  // };

  const handleCancelButton = (index) => {
    // Revert to initial state
    const updatedCardStates = [...cardStates];
    updatedCardStates[index] = initialCardStates[index];
    updatedCardStates[index].editing = false;
    setCardStates(updatedCardStates);
  };
  


  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const handleDateChange = (value, index) => {
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].value = value;
    setCardStates(updatedCardStates);
  };
 

  const chunkedTitles = chunkArray(titles, 3);
  const renderInput = (index) => {
    if (index === 0 || index === 1) {
      return (
        <input
          type="date"

          className="p-2"
          style={{ width: '100%', border: '1px solid #eee', borderRadius: '5px' }}
          value={cardStates[index].value}
          onChange={(e) => handleDateChange(e.target.value, index)}
          disabled={cardStates[index].na}
        />
      );
    } else {
      return (
        <Form.Control
          type="text"
          placeholder={`Enter ${titles[index]}`}
          value={cardStates[index].value}
          onKeyDown={(e) => {
            if (e.key === ' ' && e.target.selectionStart === 0) {
              e.preventDefault();
            }
          }}
          onChange={(e) => handleInputChange(e, index)}
          disabled={cardStates[index].na}
        />
      );
    }
  };


  return (

    <div style={{overflow:'hidden'}}>
      
      <div className="mt-4 mb-3 d-md-flex " style={{ justifyContent: 'space-between' }} >

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
         severity="success"
        open={snackbarOpen}
        autoHideDuration={4000} 
        onClose={() => setSnackbarOpen(false)}
      >
        <SnackbarContent style={{backgroundColor:"green"}} message={snackbarMessage} />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
         severity="success"
        open={errorSnackbarOpen}
        autoHideDuration={4000} 
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <SnackbarContent style={{backgroundColor:"red"}} message={snackbarErrorMessage} />
      </Snackbar>
    
        <div style={{ letterSpacing: '1px' }}>
        <div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', marginLeft:'8px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Under review : {countValidation1}</span>
          <div className="vr  mx-3"></div>
          <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Accepted : {countValidation2}</span>
          <div className="vr mx-3"></div>
          <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Rejected : {countValidation3}</span>
        </div>
        
        
         
          {/* <div style={{ backgroundColor: COLORS.bggray, width: '20px', height: '20px', display: 'inline-block', marginRight: '4px', marginLeft: '8px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Not edited</span> */}
         
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchInput}
              // onChange={handleSearchInputChange}
              style={{
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                marginRight: '8px',
                width: '100%',
                
              }}
            />
            <button className="btn btn-danger btn-small" style={{ 
                borderRadius: '5px',marginRight:'15px'}}>
              Submit
            </button>
          
          </div> */}
      </div>

      <div >
        {chunkedTitles.map((row, rowIndex) => (
          <Row key={rowIndex} >
            {row.map((title, colIndex) => {
              const index = rowIndex * 3 + colIndex;
              const updatedValue = cardStates[index].value;
              const updatedFileUrl = cardStates[index].fileUrl;
              const updatedValidation = cardStates[index].validation;
              const updatedRemark = cardStates[index].remark;
              const filename = updatedFileUrl ? updatedFileUrl.substring(updatedFileUrl.lastIndexOf('/') + 1) : '';
              const updatena = cardStates[index].na;
              const showRemark = updatedValidation !== 1;
              const validationText =  updatedValidation === 1 ? "Under Review" : updatedValidation === 2 ? "Accepted" : updatedValidation === 3 ? "Rejected" : "Not Uploaded";
              return (

                <Col   key={colIndex} >
                  <Card className={` ${cardStates[index].editing ? 'editing' : ''}  `} style={{ margin: '10px', backgroundColor: cardStates[index].background, border: '1px solid gray', height: '300px', width: '380px' }}>
                    <Card.Body>
                      {!cardStates[index].editing ? (
                        <>
                          <div className="p-3 " style={{ cursor: 'pointer', textAlign: 'center', backgroundColor: '#eee', borderRadius: '5px', boxShadow: '0px 0px 2px 0px rgba(0,0,0)' }} onClick={() => { handleEditClick(index) }}>
                            <Card.Title style={{ fontSize: '16px', letterSpacing: "0.6px"}}>{title}</Card.Title>
                          </div>
                          <div className="pt-4 d-flex" style={{justifyContent:'space-between'}}>
                            <div style={{overflowY:'auto',width:'200px',height:'auto',maxHeight:'60px'}}>
                            {updatedValue && <h6>{updatedValue}</h6>}
                            {!updatedValue && !updatedFileUrl && updatena && <p>Not Applicable</p>}</div>
                            {updatedFileUrl && (
                <div>
                  <button className='btn btn-sm btn-secondary' onClick={() => window.open(`https://docs.google.com/gview?url=${updatedFileUrl}&embedded=true`, '_blank')}><i className="bi bi-eye"></i> Doc</button>
                  <button className="btn mx-1 btn-sm btn-secondary" onClick={() => window.open(updatedFileUrl)}><i className="bi bi-download"></i> Doc</button>
                </div>
              )}
                            
                          </div>
                          {updatedRemark && showRemark && (
  <div className="d-flex pt-2">
    <div>
      <h6 style={{ backgroundColor: 'red', padding: '3px', borderRadius: '5px', color: 'white' }}>Remark:</h6>
    </div>
    <div style={{ marginLeft: '5px', width: '100%', padding: '5px', border: '1px solid gray', overflow: 'auto', height: '80px' }}>
      {updatedRemark}
    </div>
  </div>
)}
                          <p className="pt-2 " style={{ letterSpacing: '0.6px'}}>Status : {validationText}</p>
                        </>

                      ) : (
                        <>
                          <Form.Group className="mb-3">
                            <Form.Group className="d-flex ">
                              <Form.Check label="Not Applicable" type="checkbox" style={{ fontSize: '14px', marginLeft: 'auto', marginRight: '5px', marginBottom: '10px' }} checked={cardStates[index].na} onChange={() => handleNotApplicableChange(index)} />
                            </Form.Group>
                            {/* <Form.Label >{title}</Form.Label> */}
                            {renderInput(index)}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <div className="d-flex align-items-center">
                              <Form.Control
                                type="text"
                                placeholder={`Upload Proof for ${title}`}
                                // const filename={cardStates[index].fileUrl.substring(cardStates[index].fileUrl.lastIndexOf('/') + 1)}
                                value={filename}

                                readOnly
                                disabled={cardStates[index].na}
                              />
                              <Button variant="light" className="m-2" disabled={cardStates[index].na}>
                                <BiUpload style={{ fontSize: '18px' }} onClick={() => handleUploadClick(index)} />
                              </Button>
                              <Button
                                variant="light"
                                className="ms-2"
                                id="upload"
                                style={{border:'1px solid gray'}}
                                disabled={cardStates[index].na || uploadStatus[index] === 'uploading'||uploadStatus[index] === 'uploaded'}
                                onClick={() => handleFileUpload(index, title)}
                                
                              >
                                {uploadStatus[index] === 'uploading' ? 'Uploading...' : (uploadStatus[index] === 'uploaded' ? 'Uploaded' : 'Upload')}
                              </Button>
                              <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileChange(e, index)}
                                ref={fileInputRefs.current[index]}
                              />
                            </div>
                          </Form.Group>

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                           
                            <button className="btn btn-md btn-light" style={{border:"1px solid gray"}} onClick={() => handleCancelButton(index)} >Cancel</button>
                            <button className='btn btn-md btn-success' onClick={() => handlesave(index)} disabled={
                              !cardStates[index].na &&
                              (cardStates[index].value === '' || cardStates[index].fileUrl === '')
                            }>Save</button>

                          </div>
                          {/* {showRemark && (
                            <h6 className="text-danger">
                              {updatedRemark}
                            </h6>
                          )} */}
                        </>
                      )}

                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ))}
      </div>
    </div>
  );
};

export default MasterData;
