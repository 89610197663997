import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Checkbox, Radio, FormControlLabel, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccountCircle, Email, Phone, LocationOn, VerifiedUserOutlined, Lock, DateRange, FormatListNumbered, Person } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { COLORS } from '../Component/colors';
import api from '../Services/api';



const useStyles = makeStyles({
  card: {
    margin: 50,
    padding: 20
  },

  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    marginBottom: 15,
  },
  icon: {
    marginRight: 10,
  },
  textField: {
    width: '100%',
  },
  selectField: {
    width: '100%',
  },
});
const statesInIndia = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Ladakh', 'Lakshadweep', 'Puducherry'];




const CompanyCard = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    principleEmployer: '',
    stateId: '',
    placeName: '',
    companyAddress: '',
    authorisedPersonName: '',
    companyEmailId: '',
    companyPhoneNumber: '',
    clientName: '',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    isClient: true,
    clientUsername: '',
    clientPassword: '',
    startDate: '',
    endDate: '',

  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = useState({});



  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  //VALIDATION
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;
    if (!formData.principleEmployer) newErrors.principleEmployer = 'Company Name is required';
    if (!formData.stateId) newErrors.stateId = 'State is required';
    if (!formData.placeName) newErrors.placeName = 'Place is required';
    if (!formData.companyAddress) newErrors.companyAddress = 'Address is required';
    if (!formData.authorisedPersonName) newErrors.authorisedPersonName = 'Conatct Person name is required';

    if (!emailPattern.test(formData.companyEmailId)) {
      newErrors.companyEmailId = formData.companyEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(formData.companyPhoneNumber)) {
      newErrors.companyPhoneNumber = formData.companyPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    if (!formData.clientName) newErrors.clientName = 'Contractor Name  is required';
    if (!formData.clientContactPersonName) newErrors.clientContactPersonName = 'Contact Person Name is required';
    if (!emailPattern.test(formData.clientContactPersonEmailId)) {
      newErrors.clientContactPersonEmailId = formData.clientContactPersonEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(formData.clientContactPersonPhoneNumber)) {
      newErrors.clientContactPersonPhoneNumber = formData.clientContactPersonPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    if (!formData.startDate) newErrors.startDate = 'Contract Start Date is required';
    if (!formData.endDate) newErrors.endDate = 'Contract End Date is required';
    if (!formData.clientUsername) newErrors.clientUsername = 'Username is required';
    if (!formData.clientPassword) newErrors.clientPassword = 'Password is required';
    const selectField = formData.stateId;
    if (!selectField) {
      newErrors.selectField = 'Please select State';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  //CREATING CLIENT PROFILE
  const handleSave = () => {
    if (validateForm()) {
        api.admin.createClient(formData).then(response => {
          if (response.status === 201) {
            const clientData = response.data;
            sessionStorage.setItem('client', JSON.stringify(clientData));
            window.alert("Company Created Succesfully")
            setFormData({
              principleEmployer: '',
              stateId: '',
              placeName: '',
              companyAddress: '',
              authorisedPersonName: '',
              companyEmailId: '',
              companyPhoneNumber: '',
              clientName: '',
              clientContactPersonName: '',
              clientContactPersonPhoneNumber: '',
              clientContactPersonEmailId: '',
              isClient: true,
              clientUsername: '',
              clientPassword: '',
              startDate: '',
              endDate: '',
            });
            handleSubmit();
          }

          else if (response.status === 400) {
            window.alert("The username you Entered is already in use. Please enter a different one");
          }
        })
        .catch(error => {

          console.error('Error saving company data:', error);
        });
    }
  };


  //CLIENT MASTERDATA CREATION
  const handleSubmit = () => {
    let data = sessionStorage.getItem('client');
    let clientData = JSON.parse(data);
    const id = clientData.data.companyId;
    const masterData = {
      companyId: id,
      dateOfCommencement: {
        value: ""
      },
      dateOfCompletion: {
        value: ""
      },
      poCopy: {
        value: ""
      },
      epfoAllotment: {
        value: ""
      },
      esicAllotment: {
        value: ""
      },
      pTaxRc: {
        value: ""
      },
      ptEc: {
        value: ""
      },
      lwf: {
        value: ""
      },
      factoryLicence: {
        value: ""
      },
      buildingPlanLayout: {
        value: ""
      },
      stabilityCertificate: {
        value: ""
      },
      concernToEstablishment: {
        value: ""
      },
      concernToOperations: {
        value: ""
      },
      fireAdvisory: {
        value: ""
      },
      fireNoc: {
        value: ""
      },
      environmentClearance: {
        value: ""
      },
      medicalOrHospitalCertificate: {
        value: ""
      },
      apprenticeshipRegistration: {
        value: ""
      },
      neemOrNapsRegistration: {
        value: ""
      },
      clraRc: {
        value: ""
      },
      sAndCeRc: {
        value: ""
      },
      clraLicence: {
        value: ""
      },
      bocwRc: {
        value: ""
      },
      ismwRc: {
        value: ""
      },
      ismwLicence: {
        value: ""
      },
      passaraLicence: {
        value: ""
      },
      fssaiLicence: {
        value: ""
      },


    }

 
     api.admin.createMasterDataClient(masterData) .then((response) => {
      if(response.status === 200){
        window.alert("Client MasterData created successfully");
        setFormData({
          clientName: '',
          companyId: '',
          principleEmployer: '',
          companyAddress: '',
          principleEmployer: '',
          clientContactPersonName: '',
          clientContactPersonPhoneNumber: '',
          clientContactPersonEmailId: '',
          stateId: '',
          placeName: '',
          natureOfWork: '',
          vendorCompanyName: '',
          vendorName: '',
          vendorCompanyAddress: '',
          vendorEmail: '',
          vendorPhoneNumber: '',
          vendorUsername: '',
          vendorPassword: '',
          vendorContractStartDate: '',
          vendorContractEndDate: '',
        });
      }
      else{
        window.alert("Some error occured,try again");
        setFormData({
          clientName: '',
          companyId: '',
          principleEmployer: '',
          companyAddress: '',
          principleEmployer: '',
          clientContactPersonName: '',
          clientContactPersonPhoneNumber: '',
          clientContactPersonEmailId: '',
          stateId: '',
          placeName: '',
          natureOfWork: '',
          vendorCompanyName: '',
          vendorName: '',
          vendorCompanyAddress: '',
          vendorEmail: '',
          vendorPhoneNumber: '',
          vendorUsername: '',
          vendorPassword: '',
          vendorContractStartDate: '',
          vendorContractEndDate: '',
        });
      }
      })
      .catch(error => {
        console.error('Error saving company master data:', error);
      });
  };



  return (
    <div >
      <Card className={classes.card} >
        <Typography className="text-center  text-dark p-3 mb-4" variant="h5" style={{ backgroundColor: COLORS.bggray }}>
          Create Client
        </Typography>
        <CardContent>
          <Typography className="text-center mb-4 " color="textSecondary" gutterBottom>
            - - - - - - - - - <span className="text-dark"> Client Details </span> - - - - - - - - - - -
          </Typography>

        <div className={classes.detail}>
          <AccountCircle className={classes.icon} />
          <TextField className={classes.textField} label="Name of the principal Employer" name="principleEmployer" value={formData.principleEmployer} onChange={handleChange} error={!!errors.principleEmployer}
              helperText={errors.principleEmployer}/>
        </div>
       
        <div className={classes.detail}>
          <LocationOn className={classes.icon} />
          <Select
            className={classes.selectField}
            label="State ID"
            value={formData.stateId}
            onChange={handleChange}
            name="stateId"
            displayEmpty
            error={!!errors.stateId}
          >
              <MenuItem value="" disabled >Select State</MenuItem>
              {statesInIndia.map((state, index) => (

                <MenuItem key={index} value={state}>{state}</MenuItem>
              ))}
            </Select>

          </div>
          {errors.selectField && (
            <div className="pb-3 px-5" style={{ color: 'red', fontSize: '14px' }}>
              {errors.selectField}
            </div>
          )}
          <div className={classes.detail}>
            <LocationOn className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Place"
              name="placeName"
              value={formData.placeName}
              onChange={handleChange}
              placeholder="Enter your city"
              error={!!errors.placeName}
              helperText={errors.placeName}
            />
          </div>
          <div className={classes.detail}>
            <LocationOn className={classes.icon} />
            <TextField className={classes.textField} label="Address" name="companyAddress" value={formData.companyAddress} onChange={handleChange} error={!!errors.companyAddress}
              helperText={errors.companyAddress} />
          </div>
          <div className={classes.detail}>
            <AccountCircle className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Name of authorised person"
              name="authorisedPersonName"
              value={formData.authorisedPersonName}
              onChange={handleChange}
              error={!!errors.authorisedPersonName}
              helperText={errors.authorisedPersonName}
            />
          </div>

          <div className={classes.detail}>
            <Email className={classes.icon} />
            <TextField className={classes.textField} label="Email" name="companyEmailId" value={formData.companyEmailId} onChange={handleChange} error={!!errors.companyEmailId}
              helperText={errors.companyEmailId} />
          </div>
          <div className={classes.detail}>
            <Phone className={classes.icon} />
            <TextField className={classes.textField} label="Phone Number" name="companyPhoneNumber" value={formData.companyPhoneNumber} onChange={handleChange} error={!!errors.companyPhoneNumber}
              helperText={errors.companyPhoneNumber} />
          </div>

          <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
            - - - - - - - - - <span className="text-dark">Contractor  Details</span> - - - - - - - - - - -
          </Typography>


          <div className={classes.detail}>
            <Person className={classes.icon} />
            <TextField className={classes.textField} label="Name of the contractor" name="clientName" value={formData.clientName} onChange={handleChange} error={!!errors.clientName}
              helperText={errors.clientName} />
          </div>
          <div className={classes.detail}>
            <Person className={classes.icon} />
            <TextField className={classes.textField} label="Name of the authorised person" name="clientContactPersonName" value={formData.clientContactPersonName} onChange={handleChange} error={!!errors.clientContactPersonName}
              helperText={errors.clientContactPersonName} />
          </div>
          <div className={classes.detail}>
            <Email className={classes.icon} />
            <TextField className={classes.textField} label="Email" name="clientContactPersonEmailId" value={formData.clientContactPersonEmailId} onChange={handleChange} error={!!errors.clientContactPersonEmailId}
              helperText={errors.clientContactPersonEmailId} />
          </div>
          <div className={classes.detail}>
            <Phone className={classes.icon} />
            <TextField className={classes.textField} label="Phone Number" name="clientContactPersonPhoneNumber" value={formData.clientContactPersonPhoneNumber} onChange={handleChange} error={!!errors.clientContactPersonPhoneNumber}
              helperText={errors.clientContactPersonPhoneNumber} />
          </div>


          <div className={classes.detail}>
            <DateRange className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Contract Start Date"
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.startDate}
              helperText={errors.startDate}
            />
          </div>

          <div className={classes.detail}>
            <DateRange className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Contract End Date"
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.endDate}
              helperText={errors.endDate}
            />
          </div>
          <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
            - - - - - - - - - - - <span className="text-dark"> Login Details </span> - - - - - - - - - - -
          </Typography>
          <div className={classes.detail}>
            <AccountCircle className={classes.icon} />
            <TextField
              className={classes.textField}
              label="Username"
              name="clientUsername"
              value={formData.clientUsername}
              onChange={handleChange}
              error={!!errors.clientUsername}
              helperText={errors.clientUsername}
            />
          </div>
          <div className={classes.detail}>
            <Lock className={classes.icon} />
            <FormControl sx={{ width: '100%' }} variant="outlined">

              <TextField
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password"
                name="clientPassword"
                value={formData.clientPassword}
                onChange={handleChange}
                error={!!errors.clientPassword}
                helperText={errors.clientPassword}
              />
            </FormControl>
          </div>


          <button className="btn btn-dark  text-white w-100 mt-4 " disabled={!validateForm} onClick={handleSave}>Save</button>
        </CardContent>
      </Card>
    </div>
  );
};

export default CompanyCard;

