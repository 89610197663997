import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../Services/api';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';

const statesInIndia = [
  'Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh',
  'Jharkhand','Karnataka', 'Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland',
  'Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal',
  'Andaman and Nicobar Islands','Chandigarh','Dadra and Nagar Haveli and Daman and Diu','Delhi','Ladakh','Lakshadweep','Puducherry'
];

export const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [editingClient, setEditingClient] = useState(null);
  const [clientFormData, setClientFormData] = useState({
    companyId: "",
    principleEmployer: "",
    stateId: "",
    placeName: "",
    companyAddress: "",
    authorisedPersonName: "",
    companyPhoneNumber: "",
    companyEmailId: "",
    clientName: "",
    clientContactPersonName: "",
    clientContactPersonPhoneNumber: "",
    clientContactPersonEmailId: "",
    startDate: "",
    endDate: "",
  });

  const fetchClients = async () => {
    try {
      const response = await api.admin.getClients();
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleEditClick = (client) => {
    setEditingClient(client.companyId);
    setClientFormData({ ...client });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setClientFormData({ ...clientFormData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const response = await api.admin.editClient(clientFormData.companyId, clientFormData);
      if (response.status === 200) {
        window.alert("Updated successfully!");
        setEditingClient(null);
        fetchClients();
      } else {
        window.alert("Error in submitting");
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleCancel = () => {
    setEditingClient(null);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div className="m-5">
      <h5>Client List <i class="bi bi-arrow-right-square-fill" ></i></h5>
     
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{backgroundColor:'#f7f4f4'}}>
                <th>Principle Employer Details</th>
                <th>Contractor Details</th>
                <th>Action</th>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client) => (
                <TableRow key={client.companyId}>
                  <TableCell>
                  <div style={{fontSize:"16px",fontWeight:'bold'}}>{client.principleEmployer}</div>
                  <div>State: {client.stateId}</div>
                    <div>Place: {client.placeName}</div>
                    <div>Address: {client.companyAddress}</div>
                    
                    <div>Contact Person: {client.authorisedPersonName}</div>
                    <div>Email : {client.companyEmailId}</div>
                    <div>Phone: {client.companyPhoneNumber}</div>
                  </TableCell>
                  <TableCell>
                    <div style={{fontSize:"16px",fontWeight:'bold'}}>{client.clientName}</div>
                    <div>Contact Person: {client.clientContactPersonName}</div>
                    <div>Phone: {client.clientContactPersonPhoneNumber}</div>
                    <div>Email: {client.clientContactPersonEmailId}</div>
                    <div>Contract Start Date: {client.startDate?.slice(0,10)}</div>
                    <div>Contract End Date: {client.endDate?.slice(0,10)}</div>
                  </TableCell>
                  <TableCell>
                  <button className="mx-1 btn btn-sm btn-primary" onClick={() => handleEditClick(client)}>Edit</button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
     

      {/* Edit Dialog Popup */}
      <Dialog open={editingClient !== null} onClose={handleCancel} fullWidth maxWidth="md">
      <h6 style={{textAlign:'center',color:'white',backgroundColor:"#1C1C1C",padding:'24px',letterSpacing:'0.5px'}}>EDIT CLIENT DETAILS</h6>
     
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Principle Employer"
                name="principleEmployer"
                value={clientFormData.principleEmployer}
                onChange={handleFormChange}
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>State</InputLabel>
                <Select
                  name="stateId"
                  value={clientFormData.stateId}
                  onChange={handleFormChange}
                >
                  {statesInIndia.map((state, index) => (
                    <MenuItem key={index} value={clientFormData.stateId}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Company Address"
                name="companyAddress"
                value={clientFormData.companyAddress}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Place"
                name="placeName"
                value={clientFormData.placeName}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Authorised Person Name"
                name="authorisedPersonName"
                value={clientFormData.authorisedPersonName}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Email Id"
                name="companyEmailId"
                value={clientFormData.companyEmailId}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Phone Number"
                name="companyPhoneNumber"
                value={clientFormData.companyPhoneNumber}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Contractor Name"
                name="clientName"
                value={clientFormData.clientName}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Contact Person"
                name="clientContactPersonName"
                value={clientFormData.clientContactPersonName}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Email"
                name="clientContactPersonEmailId"
                value={clientFormData.clientContactPersonEmailId}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Phone"
                name="clientContactPersonPhoneNumber"
                value={clientFormData.clientContactPersonPhoneNumber}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                type="date"
                label="Contract Start Date"
                name="startDate"
                 value={clientFormData.startDate ? clientFormData.startDate.slice(0, 10) : ''}
                onChange={handleFormChange}
                margin="normal"
              />
              <TextField
                fullWidth
                type="date"
                label="Contract End Date"
                name="endDate"
                 value={clientFormData.endDate ? clientFormData.endDate.slice(0, 10) : ''}
                onChange={handleFormChange}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
       
        <div className='mx-4 mb-3' style={{display:'flex',justifyContent:'space-between'}}>
          <button className="btn btn-md btn-danger" onClick={handleCancel} >
            Cancel
          </button>
          <button className="btn btn-md btn-success" onClick={handleSave} >
            Save
          </button>
          </div>
      
      </Dialog>
    </div>
  );
};
