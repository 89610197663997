import React,{useEffect, useState,useRef} from 'react';
import api from '../Services/api';
import axios from 'axios';
import { COLORS } from '../Component/colors';
import './EditableCard.css';


const months = [
    { name: "JANUARY", value: "01" },
    { name: "FEBRUARY", value: "02"},
    { name: "MARCH", value: "03" },
    { name: "APRIL", value: "04" },
    { name: "MAY", value: "05" },
    { name: "JUNE", value: "06" },
    { name: "JULY", value: "07" },
    { name: "AUGUST", value: "08" },
    { name: "SEPTEMBER", value: "09" },
    { name: "OCTOBER", value: "10" },
    { name: "NOVEMBER", value: "11" },
    { name: "DECEMBER", value: "12" }
  ];
  const years = Array.from({ length: 2040 - 2020 + 1 }, (x, i) => 2020 + i);
const titles = [
    "The Contract Labour (Regulation & Abolition) Act, 1970",
    "Generic",
    "Minimum Wages Act, 1936",
     "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952",
     "The Employees' State Insurance Act and Regulations, 1948",
     "Professional Tax",
    "The Payment of Bonus Act, 1972",
    "Shops And Commercial Establishments Act",
    "The Maternity Benefit Act, 1961",
    "The Employees Compensation Act, 1923",
    "The Equal Remuneration Act, 1976", 
    "The Factories Act, 1948",
    "The Labour Welfare Fund Act, 1965",
   
];
const titleToKeyMap = {
    "Combined Muster Roll-cum-Register of Wages": "combinedMusterRollCumRegisterOfWages",
    "Register of Workmen": "RegisterOfWorkmen",    
    "Leave Register/Leave Book":"leaveRegisterLeaveBook",
    "Employment Card":"EmploymentCard",
    "Wage Slip": "WageSlip",
    "Service Certificate for Left Employees" : "ServiceCertificateForLeftEmployees",
    "In and out time Register": "inAndOutTimeRegister",
    "Bank Statement for Salary Credit Confirmation": "BankStatementOrSalaryCreditConfirmation",
    "PF Declaration on letterhead": "PFDeclarationOnLetterhead",
    "ESI Declaration on letterhead": "ESIDeclarationOnLetterhead",
    "Overtime Declaration on letterhead": "OvertimeDeclarationOnLetterhead",
    "Payment done as per revised rate of Minimum wages": "PaymentDoneAsPerRevisedRateOfMinimumwages",
    "PF Monthly ECR": "PFMonthlyECR",
    "PF Combined Challan": "PFCombinedChallan",
    "PF TRRN Summary": "PFTRRNSummary",
    "IW-1 International Worker Monthly Returns": "IW1InternationalWorkerMonthlyReturns",
    "ESI-Accident Register": "ESIAccidentRegister",
    "ESIC Contribution Challan": "ESICContributionChallan",
    "ESIC Monthly Statement": "ESICMonthlyStatement",
    "Pehchan Card (Insured Person)": "PehchanCard",
    "Professional Tax Returns": "professionalTaxReturns",
    "PT Challan": "PTChallan",
    "Monthly Bonus Paid or Not": "MonthlyBonusPaidOrNot",
    "Statutory Bonus Return": "statutoryBonusReturn",
    "Registration Of Establishment": "registrationOfEstablishment",
    "Appointment Order In Form Q": "appointmentOrderInFormQ",
    "Muster Roll Of Women Workers": "musterRollOfWomenWorkers",
    "Employees Compensation Policy": "employeesCompensationPolicy",
    "Equal Remuneration And Register To Be Maintained": "EqualRemunerationAndRegisterToBeMaintained",
    "Register of Overtime and Payment": "RegisterofOvertimeandPayment",
    "Register Of Leave And Leave Book": "RegisterOfLeaveAndLeaveBook",
    "Register Of Adult Workers Cum Muster Roll":"RegisterOfAdultWorkersCumMusterRoll",  
    "Combined register for Muster Roll and Wages":"CombinedregisterforMusterRollandWages",
    "Register Of Unclaimed Wages And Payment To The Board": "RegisterOfUnclaimedWagesAndPaymentToTheBoard",
    "LWF Challan": "LWFChallan",

    
    
     


};



const preferredSubTitles = {
    "The Contract Labour (Regulation & Abolition) Act, 1970": [
        "Combined Muster Roll-cum-Register of Wages",
        "Register of Workmen",
        "Leave Register/Leave Book",
        "Employment Card",
        "Wage Slip",
        "Service Certificate for Left Employees",
       
    ],
    "Generic": [
        "In and out time Register",
        "Bank Statement for Salary Credit Confirmation",
        "PF Declaration on letterhead",
        "ESI Declaration on letterhead",
        "Overtime Declaration on letterhead"
    ],
    "Minimum Wages Act, 1936": [
        "Payment done as per revised rate of Minimum wages"
    ],
    "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952": [
        "PF Monthly ECR",
        "PF Combined Challan",
        "PF TRRN Summary",
        "IW-1 International Worker Monthly Returns"
    ],
    "The Employees' State Insurance Act and Regulations, 1948": [
        "ESI-Accident Register",
        "ESIC Contribution Challan",
        "ESIC Monthly Statement",
        "Pehchan Card (Insured Person)"
    ],
    "Professional Tax": [
        "Professional Tax Returns",
        "PT Challan"
    ],
    "The Payment of Bonus Act, 1972": [
        "Monthly Bonus Paid or Not",
        "Statutory Bonus Return"
    ],
    "Shops And Commercial Establishments Act": [
        "Registration Of Establishment",
        "Appointment Order In Form Q"
    ],
    "The Maternity Benefit Act, 1961": [
        "Muster Roll Of Women Workers",
        
    ],
    "The Employees Compensation Act, 1923": [
        "Employees Compensation Policy",
        
    ],
    "The Equal Remuneration Act, 1976": [
        "Equal Remuneration And Register To Be Maintained",
        
    ],
    "The Factories Act, 1948": [
        "Register of Overtime and Payment",
        "Register Of Leave And Leave Book",
        "Register Of Adult Workers Cum Muster Roll",
        "Combined register for Muster Roll and Wages"
    ],
    "The Labour Welfare Fund Act, 1965": [
        "Register Of Unclaimed Wages And Payment To The Board",
        "LWF Challan"
    ],
    
   
};
const MonthlyDataTableAssessor = () => {
  
    const [objectivesOfTheAudit, setObjectivesOfTheAudit] = useState('');
const [auditorObservation, setAuditorObservation] = useState('');
const [auditorRecommendation, setAuditorRecommendation] = useState('');
const [selectedMonth, setSelectedMonth] = useState(''); 
const [selectedYear, setSelectedYear] = useState(null);
     const [userType, setUserType] = useState('');
    const [clients, setClients] = useState([]);
    const[assessors,setAssessors] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [monthlyData, setMonthlyData] = useState({});
    const[complianceFiledDate,setComplianceFiledDate]=useState('')
    const [saveEnabled, setSaveEnabled] = useState({});
    const [selectedVendor, setSelectedVendor] = useState('');
    const [selectedAssessor, setSelectedAssessor] = useState('');

    const fileInputRefs = useRef(Array(titles.length * Math.max(...Object.values(preferredSubTitles).map(arr => arr.length))).fill(null).map(() => React.createRef()));
    useEffect(() => {
        const data = sessionStorage.getItem('user');
        let name = JSON.parse(data);
        let type = name.type;
        setUserType(type);
        let id = name.id;
        if(type === 'assessor' ) {
fetchVendor(id);
        }
        else if(type === 'admin'){
            fetchClient(id);
        }

        
    }, []);
    useEffect(() => {

        if (selectedVendor && selectedMonth && selectedYear) {
          fetchVendorData(selectedVendor, selectedMonth, selectedYear);
        }
      }, [selectedVendor, selectedMonth, selectedYear]);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        
       
    };
    
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
       
    };
    const fetchClient = async (id) => {
        try{
    
            const response = await api.admin.getClients(id);
            const clientData = response.data;
            setClients(clientData);
            console.log("clientData",clientData || []);
        } catch(error){
            console.error("error");
        }
       }
         const fetchAssessorData = async (companyId) => {
            try{
                const response = await api.admin.getassessor(companyId);
                const assessorData = response.data.data;
                console.log("assessorData",assessorData);
                setAssessors(assessorData);
    
            }catch(error){
                console.error("error");
            }
         }
      const fetchVendor = async (assessorId) => {
        
        try {
            const response = await api.assessor.getVendors(assessorId);
            const vendorData = response.data.data;
            console.log("vendorData",vendorData)
           setVendors(vendorData);
    
            
          
        } catch (error) {
            console.error("Vendor ID could not be fetched:", error);
        }
    };
    const handleClientChange = async (event) => {
        const companyId = event.target.value;
        console.log("idselected",companyId);
        setSelectedClient(companyId);
         fetchAssessorData(companyId); 
    };
     const handleAssessorChange = async (event) => {
        const assessorId = event.target.value;
        setSelectedAssessor(assessorId);
        fetchVendor(assessorId);
       
     };
     const handleVendorChange = async(event) => {
        const vendorId = event.target.value;
        sessionStorage.setItem('selectedVendorId', vendorId); 
        setSelectedVendor(vendorId);
     }
  
  

    const fetchVendorData = async (vendorId,month,year) => {
        try {
            const response = await api.vendor.getMonthlyData(vendorId,month,year);
            if(response.status===200){
            const data = response.data.data || {};
            setSelectedDate(data.complianceFiledDate);
            setObjectivesOfTheAudit(data.objectivesOfTheAudit);
            setAuditorObservation(data.auditorObservation);
            setAuditorRecommendation(data.auditorRecommendation);
            
            setComplianceFiledDate(data.complianceFiledDate);
            
            const initializedData = {};
            Object.keys(data).forEach((key) => {
                const entry = data[key];
                initializedData[key] = {
                    fileUrl: entry.fileUrl || '',
                    validation: entry.validation !== null ? entry.validation : 0,
                    remark: entry.remark || '',
                    vendorRemark: entry.vendorRemark || '',
                    na:entry.na||false
                };
            });
            setMonthlyData(initializedData);
        }
        else if(response.status===400){
            window.alert("Vendor data not found for selected month and year");
            // sessionStorage.removeItem('selectedVendorId');
            
            setMonthlyData({});
            setSelectedDate(null); 
            setComplianceFiledDate('')

        }
            
        } catch (error) {
            console.error('Error fetching monthly data', error);
        }
    };

    const handleCheckboxChange = (subTitle, action) => {
        setMonthlyData(prevData => {
            const entry = prevData[titleToKeyMap[subTitle]] || {};
            const isAccepted = action === 'accept';
            const isRejected = action === 'reject';

            const newValidation = isAccepted ? 2 : isRejected ? 3 : 0;
            const newRemark = isRejected && !entry.remark ? '' : entry.remark;

            return {
                ...prevData,
                [titleToKeyMap[subTitle]]: {
                    ...entry,
                    validation: newValidation,
                    remark: newRemark,
                }
            };
        });
        setSaveEnabled(prev => ({
            ...prev,
            [titleToKeyMap[subTitle]]: true,
        }));
    };

    const handleRemarkChange = (subTitle, newRemark) => {
        setMonthlyData(prevData => {
            const entry = prevData[titleToKeyMap[subTitle]] || {};
            return {
                ...prevData,
                [titleToKeyMap[subTitle]]: {
                    ...entry,
                    remark: newRemark,
                }
            };
        });
    };
    function handleEnterKey(event, textareaId) {
        const textarea = document.getElementById(textareaId);
    
        if (event.key === "Enter") {
            event.preventDefault();  
    
            const cursorPosition = textarea.selectionStart;
            const textBefore = textarea.value.substring(0, cursorPosition);
            const textAfter = textarea.value.substring(cursorPosition);
            textarea.value = `${textBefore}\n• ${textAfter}`;
            textarea.selectionStart = textarea.selectionEnd = cursorPosition + 3;  
            textarea.focus();
        }
    }
    
    function insertBullet(textareaId) {
        const textarea = document.getElementById(textareaId);
        const cursorPosition = textarea.selectionStart;
        const textBefore = textarea.value.substring(0, cursorPosition);
        const textAfter = textarea.value.substring(cursorPosition);
        textarea.value = `${textBefore}• ${textAfter}`;
        textarea.selectionStart = textarea.selectionEnd = cursorPosition + 2;
        textarea.focus();
    }
    
      
      function insertBullet(textareaId) {
        const textarea = document.getElementById(textareaId);  
        const cursorPosition = textarea.selectionStart;  
        const textBefore = textarea.value.substring(0, cursorPosition);  
        const textAfter = textarea.value.substring(cursorPosition);  
        textarea.value = `${textBefore}• ${textAfter}`;
        textarea.selectionStart = textarea.selectionEnd = cursorPosition + 2;  
        textarea.focus();  
      }
    const handleSave = async (subTitle) => {
        const id = sessionStorage.getItem('selectedVendorId');
        const updatedEntry = monthlyData[titleToKeyMap[subTitle]] || {};
console.log("selectdDate",selectedDate)
        const Monthlydata = {
            vendorId: id,
            month:selectedMonth,
            year:selectedYear,
            complianceFiledDate: selectedDate,
            objectivesOfTheAudit: objectivesOfTheAudit || "not data",
            auditorObservation: auditorObservation|| "no data",
            auditorRecommendation: auditorRecommendation||"no data"
        };

        const key = titleToKeyMap[subTitle];
        if (key) {
            Monthlydata[key] = {
                fileUrl: updatedEntry.fileUrl,
                validation: updatedEntry.validation,
                remark: updatedEntry.remark,
                vendorRemark: updatedEntry.vendorRemark,
                na:updatedEntry.na
               
            };
        }

        try {
            const response = await axios.put('https://www.grcpulse.in/api/monthlydata/update', Monthlydata);
            if (response.status === 200) {
                alert("Updated");
            }
            else if(response.status === 400){
                alert("Monthly data not found for selected month and year")
            }
        } catch (error) {
            console.error('Error saving monthly data', error);
        }
    };

    const renderRows = () => {
        return titles.map((title, titleIndex) => (
            <React.Fragment key={titleIndex}>
                <tr style={{ backgroundColor: COLORS.bggray }}>
                    <td colSpan={6}><strong className='text-dark'>{title}</strong></td>
                </tr>
                {preferredSubTitles[title].map((subTitle, subTitleIndex) => {
                    const dataEntry = monthlyData[titleToKeyMap[subTitle]] || {};
                    const { validation = 0, remark = '', vendorRemark = '',fileUrl = '' ,na=false} = dataEntry;

                    const rowStyle = validation === 2 ? { backgroundColor: 'lightgreen' } :
                                     validation === 3 ? { backgroundColor: 'lightcoral' } : validation === 1 ? {backgroundColor:COLORS.bggray}:{backgroundColor:'transparent'}
                                    

                    return (
                        <>
                        <tr key={subTitleIndex} style={rowStyle}>
                            <td>{subTitle}
                            <td>
                                <input
                                    type="file"
                                    ref={fileInputRefs.current[titleIndex * preferredSubTitles[title].length + subTitleIndex]}
                                />
                                <button className="btn btn-sm btn-light" style={{ border: '1px solid gray' }}>
                                    Upload
                                </button>
                               
                            </td>
                         
                               
                            </td>
                            <td>
    {fileUrl ? (
        <div className="mt-2">
            <button 
                className='btn btn-sm btn-secondary' 
                onClick={() => window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')}
            >
                <i className="bi bi-eye"></i> Doc
            </button>
            <button 
                className="btn mx-1 btn-sm btn-secondary" 
                onClick={() => window.open(fileUrl)}
            >
                <i className="bi bi-download"></i> Doc
            </button>
          
        </div>
    ) : (
        na ? (
            <span>Not Applicable</span>  
        ) : (
            <span>Not Uploaded</span>  
        )
    )}
</td>

                                
                        <td>
                                <label>
                                    <input type="checkbox" checked={validation === 2} onChange={() => handleCheckboxChange(subTitle, 'accept')} /> Accept
                                </label>
                                <label className="mx-2" >
                                    <input type="checkbox" checked={validation === 3} onChange={() => handleCheckboxChange(subTitle, 'reject')} /> Reject
                                </label>
                            </td>
                          
                     <td>
                                <textarea
                                    value={remark}
                                    placeholder="Enter your remarks here"
                                    onChange={(e) => handleRemarkChange(subTitle, e.target.value)}
                                    rows="2"
                                    style={{ width: '100%' }}
                                />
                                  <div >
                                {vendorRemark && (
            <div style={{overflow:'auto',width:'200px'}}>vendor remark: {vendorRemark}</div>
        )}
                                </div>
                            </td>

                       
                            
                        
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <button className="btn btn-md btn-success" onClick={() => handleSave(subTitle)} disabled={!saveEnabled[titleToKeyMap[subTitle]]}> Save</button>
                                    <button className='btn btn-md btn-light mx-2' style={{ border: '1px solid gray' }}>Cancel</button>
                                </div>
                        
                            </td>
                           
                        </tr>
                       
                       
                        </>
                    );
                })}
            </React.Fragment>
        ));
    };
    const renderDropdowns = () => {
        if (userType === 'admin') {
            return (
                
            
                      
                 <div className="d-flex  mt-2 p-4" style={{border:'1px solid gray',backgroundColor:COLORS.secondary}}>
                
                    <select className="p-1" onChange={handleClientChange}>
                        <option value="select">Select Client</option>
                        {clients.map(client => (
                            <option key={client.companyId} value={client.companyId}>
                                {client.clientName} {client.stateId} 
                            </option>
                        ))}
                    </select>
                    <select className="p-1 mx-2"  onChange={handleAssessorChange}>
                        <option value="select">Select Assessor</option>
                        {Array.isArray(assessors) && assessors.map((assessor) => (
                    <option key={assessor.assessorId} value={assessor.assessorId}>
                        {assessor.assessorCompanyName} {assessor.site}
                    </option>
                ))}
                    </select>
                    <select className="p-1 mx-2"   onChange={handleVendorChange}>
                        <option value="select">Select Vendor</option>
                        {vendors && vendors.map(vendor => (
                            <option key={vendor.vendorId} value={vendor.vendorId}>
                                {vendor.vendorCompanyName}
                            </option>
                        ))}
                    </select>
                <select className="p-1 mx-2"  value={selectedMonth || ''} onChange={handleMonthChange}>
                    <option value="">Select Month</option>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.name}
                        </option>
                    ))}
                </select>
                <select className="p-1" value={selectedYear || ''} onChange={handleYearChange} >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                
                </div>
    
            );
        } else {
            return (
                
                <div className='d-flex mt-2 p-4' style={{border:'1px solid gray',backgroundColor:COLORS.secondary}}>
              
                 <select className='p-1' onChange={handleVendorChange}>
                    <option value="select">Select Vendor</option>
                    {vendors && vendors.map(vendor => (
                        <option key={vendor.vendorId} value={vendor.vendorId}>
                            {vendor.vendorCompanyName}
                        </option>
                    ))}
                </select>
               
                <select className="p-1 mx-2" value={selectedMonth || ''} onChange={handleMonthChange}>
                    <option value="">Select Month</option>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.name}
                        </option>
                    ))}
                </select>
                <select className='p-1'  value={selectedYear || ''} onChange={handleYearChange} >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
     
                 </div>
            
                
            );
        }
    };
    return (
        <>
<div className='px-2 pt-4' style={{ letterSpacing: '1px' }}>
<div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Under review</span>
  <div className="vr  mx-3"></div>
  <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Accepted</span>
  <div className="vr mx-3"></div>
  <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Rejected</span>
</div>  


 


{renderDropdowns()}
{complianceFiledDate && (
    <p className=' bg-warning p-2' style={{width:'400px'}}>
  {complianceFiledDate ? `Compliance Filed Date: ${complianceFiledDate.slice(0, 10)}` : ""}
</p>
)}
<div>
    <table className=" fixed-header-table" border="1" >
        <thead>
            <tr>
                <th>Title</th>
                <th>Uploaded File</th>
                <th>Action</th>
                <th>Remark</th>
                 <th>Action</th>
               
            </tr>
        </thead>
        <tbody>
            {renderRows()}
        </tbody>
        <tr > 
  <td colSpan="4">
  <textarea
value={objectivesOfTheAudit}
onChange={(e) => setObjectivesOfTheAudit(e.target.value)}
style={{ width: '100%', height: '80px' }}
placeholder="Objective Of the Audit"
/>

  </td>
  <td colSpan="2">
 <button className='btn btn-dark btn-md' style={{position:'relative',top:'50px'}} onClick={() => handleSave('objectivesOfTheAudit')} >Save</button>
  </td>
</tr>
     <tr>

<td colSpan="2">
<div style={{ position: 'relative' }}>


<i className="fa fa-list-ul"   onClick={() => insertBullet('observationTextarea')}></i> 

<textarea
id="observationTextarea"
value={auditorObservation}
onChange={(e) => setAuditorObservation(e.target.value)}
style={{ width: '100%', height: '80px' }}
placeholder="Enter Auditor Observation"
onKeyDown={(e) => handleEnterKey(e, 'observationTextarea')}
/>

</div>
</td>
<td colSpan="2">
<div style={{ position: 'relative' }}>
<i className="fa fa-list-ul" onClick={() => insertBullet('recommendationTextarea')}></i> 

<textarea
id="recommendationTextarea"
value={auditorRecommendation}
onChange={(e) => setAuditorRecommendation(e.target.value)}
style={{ width: '100%', height: '80px' }}
placeholder="Enter Auditor Recommendation"
onKeyDown={(e) => handleEnterKey(e, 'recommendationTextarea')}
/>

</div>
</td>

</tr> 

    </table>
</div>

         

       
         
       
        </>
    );
};

export default MonthlyDataTableAssessor;
