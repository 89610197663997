import axios from "axios";
import setupInterceptorsTo from "./AxiosInterceptor";

// const BASE_URL = process.env.REACT_APP_LOCAL_URL;
setupInterceptorsTo(axios);


const api = {
    client:{
        login: (data) => {
            return axios.post('https://www.grcpulse.in/api/companyMasterProfile/login', data);
        },
        getClient: (id) => {
            return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/findOne/${id?id:'id'}`);
        },
        editClient: (id,data) => {
           return axios.put(`https://www.grcpulse.in/api/companyMasterProfile/updateOne/${id?id:'id'}`,data);
        }
        
        
       
      
    },



    admin:{
        login: (data) => {
            return axios.post('https://www.grcpulse.in/api/admin/AdminLogin',data);
        },
        getassessor: (id,data) => {
            return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/masterdata/${id?id:'id'}`, data);
        },
        getAssessors: () => {
            return axios.get(`https://www.grcpulse.in/api/Assessor/allassessors`);
        },
        createAssessor: (data) => {
            return axios.post(`https://www.grcpulse.in/api/Assessor/create-assessor`,data);
                },
        createClient: (data) => {
             return axios.post(`https://www.grcpulse.in/api/companyMasterProfile/newCompanyProfile`,data);
                },
        createMasterDataClient: (data) => {
                return axios.post(`https://www.grcpulse.in/api/companyMasterData/newMasterData`,data);    
                },
        editAssessor: (id,data) => {
            return axios.put(`https://www.grcpulse.in/api/Assessor/edit-assessor/${id?id:'id'}`,data);
        },
        getClient: (id) => {
            return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/findOne/${id?id:'id'}`);
        },
        getVendorMasterData: (id) => {
            return axios.get(`https://www.grcpulse.in/api/companyMasterData/vendorDetails/${id?id:'id'}`);
        },
        getClients: () => {
            return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/comapanyNames`);
        },
        editClient: (id,data) => {
            return axios.put(`https://www.grcpulse.in/api/companyMasterProfile/updateOne/${id?id:'id'}`,data);
        },
        updateVendorMasterData: (data) => {
            return axios.put(`https://www.grcpulse.in/api/companyMasterData/updateMasterData`,data);
        }
    },

    assessor:{
        login:(data)=>{
            return axios.post('https://www.grcpulse.in/api/Assessor/assessor-login',data);
        },
       
        findOne: (id,data) => {
            return axios.get(`https://www.grcpulse.in/api/Assessor/assessor-details/${id?id:"id"}`,data);
        },
        getVendors: (id) => {
            return axios.get(`https://www.grcpulse.in/api/Assessor/vendor's-details/${id?id:"id"}`);
        },
        editVendor: (id,data) => {
            return axios.put(`https://www.grcpulse.in/api/Assessor/edit-vendor_details/${id?id:"id"}`,data);
        },
        getClient: (id) => {
            return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/findOne/${id?id:'id'}`);
        },
        editAssessor: (id,data) => {
               return axios.put(`https://www.grcpulse.in/api/Assessor/edit-assessor/${id?id:'id'}`,data);
        }
       
       


    },

    vendor:{
        login: (data) => {
            return axios.post('https://www.grcpulse.in/api/vendorMasterProfile/login', data);
        },
        findvendor: () => {
            return axios.get('https://www.grcpulse.in/api/vendorMasterProfile/findOne');
        },
        updateOne: (id,data) => {
            return axios.put(`https://www.grcpulse.in/api/vendorMasterProfile/updateOne/${id?id:":id"}`,data)
        },
        findOne: (id,data) =>{
            return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/findOne/${id}`,data);
        },
        master:(data) => {
            return axios.put('https://www.grcpulse.in/api/companyMasterData/updateMasterData',data);
        },
     fetchVendorDetailsById:(id)=>{
      return axios.get(`https://www.grcpulse.in/api/vendorMasterProfile/vendorDetails/${id?id:":id"}`);
     },
        getMonthlyData: (id, month, year) => {
            return axios.get(`https://www.grcpulse.in/api/monthlydata/getdata/${id?id:":id"}`, {params: {month, year}});
        },
        deleteVendors : (id) => {
            return axios.put(`https://www.grcpulse.in/api/vendorMasterProfile/delete/${id?id:":id"}`);
        },
  
    },
};  

export default api;