import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../Services/api';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Select,
    Button,
    MenuItem,
    TextField,
    InputLabel,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';

const statesInIndia = ['Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka', 'Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland', 'Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Andaman and Nicobar Islands','Chandigarh','Dadra and Nagar Haveli and Daman and Diu','Delhi','Ladakh','Lakshadweep','Puducherry'];

export const AssessorList = () => {
    const [assessors, setAssessors] = useState([]);
    const [editingAssessor, setEditingAssessor] = useState(null);
    const [assessorFormData, setAssessorFormData] = useState({
        assessorId: '',
        companyId: '',
        principleEmployer: '',
        stateId: '',
        placeName:'',
        companyAddress: '',
        authorisedPersonName: '',
        companyEmailId: '',
        companyPhoneNumber: '',
        clientName: '',
        clientContactPersonName: '',
        clientContactPersonPhoneNumber: '',
        clientContactPersonEmailId: '',
        assessorCompanyName: '',
        assessorAddress: '',
        assessorName: '',
        assessorEmail: '',
        assessorPhoneNumber: '',
        joiningDate: '',
        exitDate: '',
    });
    const [companyOptions, setCompanyOptions] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);

    const fetchClients = async () => {
        try {
            const response = await api.admin.getClients();
            setCompanyOptions(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const fetchAssessors = async () => {
        try {
            const response = await api.admin.getAssessors();
            setAssessors(response.data.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const handleEditClick = (assessor) => {
        setEditingAssessor(assessor.assessorId);
        setAssessorFormData({ ...assessor });
        setOpenEditModal(true);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setAssessorFormData({ ...assessorFormData, [name]: value });

        if (name === 'principleEmployer') {
            axios.get(`https://www.grcpulse.in/api/companyMasterProfile/comapanyNames`)
                .then(response => {
                    const selectedCompany = response.data.find(company => company.principleEmployer === value);
                    if (selectedCompany) {
                        setAssessorFormData(prevData => ({
                            ...prevData,
                            principleEmployer: value,
                            companyId: selectedCompany.companyId,
                            stateId: selectedCompany.stateId,
                            placeName:selectedCompany.placeName,
                            companyAddress: selectedCompany.companyAddress,
                            authorisedPersonName: selectedCompany.authorisedPersonName,
                            companyEmailId: selectedCompany.companyEmailId,
                            companyPhoneNumber: selectedCompany.companyPhoneNumber,
                            clientName: selectedCompany.clientName,
                            clientContactPersonName: selectedCompany.clientContactPersonName,
                            clientContactPersonPhoneNumber: selectedCompany.clientContactPersonPhoneNumber,
                            clientContactPersonEmailId: selectedCompany.clientContactPersonEmailId,
                        }));
                    }
                });
        }
    };

    const handleSave = async () => {
        try {
            const response = await api.admin.editAssessor(assessorFormData.assessorId, assessorFormData);
            if (response.status === 200) {
                window.alert("Updated successfully!");
                setOpenEditModal(false);
                fetchAssessors();
            } else {
                window.alert("Error in submitting");
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleCancel = () => {
        setOpenEditModal(false);
        setEditingAssessor(null);
    };

    useEffect(() => {
        fetchAssessors();
        fetchClients();
    }, []);

    return (
        <div className="m-5">
<h5>Assessor List <i class="bi bi-arrow-right-square-fill" ></i></h5>
            <table className="mt-4">
                <thead style={{backgroundColor:'#f7f4f4'}}>
                    <tr>
                        <th>Assessor Details</th>
                        <th>Contractor Details</th>
                        <th>Principle Employer</th>
                      
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {assessors.map((assessor) => (
                        <tr key={assessor.assessorId}>
                            <td ><span style={{fontSize:"16px",fontWeight:'bold'}}>{assessor.assessorCompanyName}</span> <br></br>
                              Address: {assessor.assessorAddress} <br></br>
                              Contact Person: {assessor.assessorName}
                               <br></br>Site: {assessor.site}
                              Email:{assessor.assessorEmail} <br></br>Phone: {assessor.assessorPhoneNumber}<br></br>
                               Joining Date: {assessor.joiningDate?.slice(0, 10)}<br></br>
                               Exit Date: {assessor.exitDate?.slice(0, 10)} 
                              </td>
                              <td><span style={{fontSize:'16px',fontWeight:'bold'}}>{assessor.clientName}</span><br></br>Contact Person: {assessor.clientContactPersonName}
                            <br></br>Email: {assessor.clientContactPersonEmailId}
                            <br></br>Phone: {assessor.clientContactPersonPhoneNumber}</td>
                            <td><span style={{fontSize:'16px',fontWeight:'bold'}}>{assessor.principleEmployer}</span><br></br>Place: {assessor.placeName}<br>
                            </br>State: {assessor.stateId}<br></br>Address: {assessor.companyAddress}
                            </td>
                            
                            <td>
                            <button className="mx-1 btn btn-sm btn-primary" onClick={() => handleEditClick(assessor)}>Edit</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Dialog open={openEditModal} onClose={handleCancel}>
            <h6 style={{textAlign:'center',color:'white',backgroundColor:"#1C1C1C",padding:'24px',letterSpacing:'0.5px'}}>EDIT ASSESSOR DETAILS</h6>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Assessor Company Name"
                                name="assessorCompanyName"
                                value={assessorFormData.assessorCompanyName}
                                onChange={handleFormChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Assessor Address"
                                name="assessorAddress"
                                value={assessorFormData.assessorAddress}
                                onChange={handleFormChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Assessor Name"
                                name="assessorName"
                                value={assessorFormData.assessorName}
                                onChange={handleFormChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Assessor Email"
                                name="assessorEmail"
                                value={assessorFormData.assessorEmail}
                                onChange={handleFormChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Assessor Phone"
                                name="assessorPhoneNumber"
                                value={assessorFormData.assessorPhoneNumber}
                                onChange={handleFormChange}
                                margin="normal"
                            />
                             <TextField
                                fullWidth
                                label="Assessor Site"
                                name="site"
                                value={assessorFormData.site}
                                onChange={handleFormChange}
                                margin="normal"
                            />
                            <TextField
                fullWidth
                type="date"
                label="Joining Date"
                name="joiningDate"
                 value={assessorFormData.joiningDate ? assessorFormData.joiningDate.slice(0, 10) : ''}
                onChange={handleFormChange}
                margin="normal"
              />
                         <TextField
                fullWidth
                type="date"
                label="Exit Date"
                name="exitDate"
                 value={assessorFormData.exitDate ? assessorFormData.exitDate.slice(0, 10) : ''}
                onChange={handleFormChange}
                margin="normal"
              />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Principle Employer</InputLabel>
                                <Select
                                    name="principleEmployer"
                                    value={assessorFormData.principleEmployer}
                                    onChange={handleFormChange}
                                    
                                >
                                    {companyOptions.map((company) => (
                                        <MenuItem key={company.companyId} value={company.principleEmployer}>
                                            {company.principleEmployer}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                          
                             <TextField
                          fullWidth
                          label="State"
                          name="stateId"
                          value={assessorFormData.stateId}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                        
                        <TextField
                          fullWidth
                          label="place"
                          name="placeName"
                          value={assessorFormData.placeName}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                         <TextField
                          fullWidth
                          label="Address"
                          name="companyAddress"
                          value={assessorFormData.companyAddress}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                        
                        <TextField
                          fullWidth
                          label="Client"
                          name="clientName"
                          value={assessorFormData.clientName}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                        <TextField
                          fullWidth
                          label="Contact Person Name"
                          name="clientContactPersonName"
                          value={assessorFormData.clientContactPersonName}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                         <TextField
                          fullWidth
                          label="Email"
                          name="clientContactPersonEmailId"
                          value={assessorFormData.clientContactPersonEmailId}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                          <TextField
                          fullWidth
                          label="Phone"
                          name="clientContactPersonPhoneNumber"
                          value={assessorFormData.clientContactPersonPhoneNumber}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />

                        </Grid>
                    </Grid>
                </DialogContent>
                <div className='mx-4 mb-3' style={{display:'flex',justifyContent:'space-between'}}>
          <button className="btn btn-md btn-danger" onClick={handleCancel} >
            Cancel
          </button>
          <button className="btn btn-md btn-success" onClick={handleSave} >
            Save
          </button>
          </div>
            </Dialog>
        </div>
    );
};
