import React, { useState ,useEffect} from 'react';
import { TextField, Button, Card, CardContent ,Typography,Box} from '@mui/material';
import grclogo from '../Images/GRC-Pulse-Logo.png';
import { useNavigate } from 'react-router-dom';
import Login from './Login';


const Forgot = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(1); 
  
 
  const [seconds, setSeconds] = useState(60);


  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("OTP Expired!");
     
    }
  }, [seconds]);

  
  const convertHMS = (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return minutes + ':' + seconds; // Return is HH : MM : SS
  }
  
  const handleResend = () => {
   
    setSeconds(60);
   
  }

  const handleSendOtp = () => {
  
  
    setStep(2);
  };

  const handleVerifyOtp = () => {
  
    setStep(3);
  };

  const handleResetPassword = () => {
  
  };


  return (
    <section>
    <div style={{alignItems:'center',justifyContent:'center',display:'flex',justifyItems:'center',margin:'50px'}}>
      <CardContent  style={{backgroundColor:'white',padding:"70px",boxShadow: '8px 8px 8px 8px rgba(200,0,0,0.2)',borderRadius:'10px'}}>
     <div style={{ textAlign: 'center',paddingBottom:'10px'}}>
                            <img src={grclogo} alt="logo" width="auto" height="60px" ></img>
                        </div> 
        {step === 1 && (
          
          <div className="d-grid" >
            <Typography className="text-center " variant='secondary' >Forgot Password</Typography>
            <TextField 
            className="mt-2 mb-3"
              label="Email"
              variant="outlined"
              value={email}
              
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="btn btn-danger " onClick={handleSendOtp}>Send OTP</button>
          </div>
        )}
        {step === 2 && (
          <div className='d-grid'>
             <Typography className="text-center " variant='secondary' >Forgot Password</Typography>
            <TextField
            className="mt-2 mb-3"
              label="OTP"
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            
            />
            <button className="btn btn-danger" onClick={handleVerifyOtp}>Verify OTP</button>
            <Box >
              {seconds == "OTP Expired!" ?(
              <Typography color="text"  variant="body2" align="center" >
              OTP Expired?{" "} <Typography
                      sx={{ cursor: "pointer" }}
                      variant="button"
                      color="error"
                      
                      justifyContent="center"
                      textGradient
                      onClick={()=> handleResend()}
                    >
                      Resend OTP
                    </Typography>
            </Typography>
                ):(
                  <Typography align="center" color="text" variant="body2">
                  OTP Expires in <Typography variant="button" color="error" fontWeight="medium">
                    {convertHMS(seconds)}</Typography>
                </Typography>
                )}
             
            </Box>
          </div>
        )}
        {step === 3 && (
          <div className="d-grid">
             <Typography className="text-center " variant='secondary' >Reset Password</Typography>
            <TextField
             className="mt-2 mb-3"
              type="password"
              label="New Password"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
        className=" mb-3"
              type="password"
              label="Confirm Password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button className="btn btn-danger" onClick={handleResetPassword}>Reset Password</button>
          </div>
        )}
    
          
      </CardContent>
    </div>
    </section>
   
  );
};

export default Forgot;
