import React, { useState,useEffect ,useRef} from 'react';
import './EditableCard.css';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import axios from 'axios';
import api from '../Services/api';
import {COLORS} from './colors';
import MonthlyReport from './MonthlyReport';
const months = [
    { name: "JANUARY", value: "01" },
    { name: "FEBRUARY", value: "02"},
    { name: "MARCH", value: "03" },
    { name: "APRIL", value: "04" },
    { name: "MAY", value: "05" },
    { name: "JUNE", value: "06" },
    { name: "JULY", value: "07" },
    { name: "AUGUST", value: "08" },
    { name: "SEPTEMBER", value: "09" },
    { name: "OCTOBER", value: "10" },
    { name: "NOVEMBER", value: "11" },
    { name: "DECEMBER", value: "12" }
  ];
  const years = Array.from({ length: 2040 - 2020 + 1 }, (x, i) => 2020 + i);
  const titles = [
    "The Contract Labour (Regulation & Abolition) Act, 1970",
    "Generic",
    "Minimum Wages Act, 1936",
     "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952",
     "The Employees' State Insurance Act and Regulations, 1948",
     "Professional Tax",
    "The Payment of Bonus Act, 1972",
    "Shops And Commercial Establishments Act",
    "The Maternity Benefit Act, 1961",
    "The Employees Compensation Act, 1923",
    "The Equal Remuneration Act, 1976", 
    "The Factories Act, 1948",
    "The Labour Welfare Fund Act, 1965",
   
];
const titleToKeyMap = {
    "Combined Muster Roll-cum-Register of Wages": "combinedMusterRollCumRegisterOfWages",
    "Register of Workmen": "RegisterOfWorkmen",    
    "Leave Register/Leave Book":"leaveRegisterLeaveBook",
    "Employment Card":"EmploymentCard",
    "Wage Slip": "WageSlip",
    "Service Certificate for Left Employees" : "ServiceCertificateForLeftEmployees",
    "In and out time Register": "inAndOutTimeRegister",
    "Bank Statement for Salary Credit Confirmation": "BankStatementOrSalaryCreditConfirmation",
    "PF Declaration on letterhead": "PFDeclarationOnLetterhead",
    "ESI Declaration on letterhead": "ESIDeclarationOnLetterhead",
    "Overtime Declaration on letterhead": "OvertimeDeclarationOnLetterhead",
    "Payment done as per revised rate of Minimum wages": "PaymentDoneAsPerRevisedRateOfMinimumwages",
    "PF Monthly ECR": "PFMonthlyECR",
    "PF Combined Challan": "PFCombinedChallan",
    "PF TRRN Summary": "PFTRRNSummary",
    "IW-1 International Worker Monthly Returns": "IW1InternationalWorkerMonthlyReturns",
    "ESI-Accident Register": "ESIAccidentRegister",
    "ESIC Contribution Challan": "ESICContributionChallan",
    "ESIC Monthly Statement": "ESICMonthlyStatement",
    "Pehchan Card (Insured Person)": "PehchanCard",
    "Professional Tax Returns": "professionalTaxReturns",
    "PT Challan": "PTChallan",
    "Monthly Bonus Paid or Not": "MonthlyBonusPaidOrNot",
    "Statutory Bonus Return": "statutoryBonusReturn",
    "Registration Of Establishment": "registrationOfEstablishment",
    "Appointment Order In Form Q": "appointmentOrderInFormQ",
    "Muster Roll Of Women Workers": "musterRollOfWomenWorkers",
    "Employees Compensation Policy": "employeesCompensationPolicy",
    "Equal Remuneration And Register To Be Maintained": "EqualRemunerationAndRegisterToBeMaintained",
    "Register of Overtime and Payment": "RegisterofOvertimeandPayment",
    "Register Of Leave And Leave Book": "RegisterOfLeaveAndLeaveBook",
    "Register Of Adult Workers Cum Muster Roll":"RegisterOfAdultWorkersCumMusterRoll",  
    "Combined register for Muster Roll and Wages":"CombinedregisterforMusterRollandWages",
    "Register Of Unclaimed Wages And Payment To The Board": "RegisterOfUnclaimedWagesAndPaymentToTheBoard",
    "LWF Challan": "LWFChallan",

    
    
     


};



const preferredSubTitles = {
    "The Contract Labour (Regulation & Abolition) Act, 1970": [
        "Combined Muster Roll-cum-Register of Wages",
        "Register of Workmen",
        "Leave Register/Leave Book",
        "Employment Card",
        "Wage Slip",
        "Service Certificate for Left Employees",
       
    ],
    "Generic": [
        "In and out time Register",
        "Bank Statement for Salary Credit Confirmation",
        "PF Declaration on letterhead",
        "ESI Declaration on letterhead",
        "Overtime Declaration on letterhead"
    ],
    "Minimum Wages Act, 1936": [
        "Payment done as per revised rate of Minimum wages"
    ],
    "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952": [
        "PF Monthly ECR",
        "PF Combined Challan",
        "PF TRRN Summary",
        "IW-1 International Worker Monthly Returns"
    ],
    "The Employees' State Insurance Act and Regulations, 1948": [
        "ESI-Accident Register",
        "ESIC Contribution Challan",
        "ESIC Monthly Statement",
        "Pehchan Card (Insured Person)"
    ],
    "Professional Tax": [
        "Professional Tax Returns",
        "PT Challan"
    ],
    "The Payment of Bonus Act, 1972": [
        "Monthly Bonus Paid or Not",
        "Statutory Bonus Return"
    ],
    "Shops And Commercial Establishments Act": [
        "Registration Of Establishment",
        "Appointment Order In Form Q"
    ],
    "The Maternity Benefit Act, 1961": [
        "Muster Roll Of Women Workers",
        
    ],
    "The Employees Compensation Act, 1923": [
        "Employees Compensation Policy",
        
    ],
    "The Equal Remuneration Act, 1976": [
        "Equal Remuneration And Register To Be Maintained",
        
    ],
    "The Factories Act, 1948": [
        "Register of Overtime and Payment",
        "Register Of Leave And Leave Book",
        "Register Of Adult Workers Cum Muster Roll",
        "Combined register for Muster Roll and Wages"
    ],
    "The Labour Welfare Fund Act, 1965": [
        "Register Of Unclaimed Wages And Payment To The Board",
        "LWF Challan"
    ],
    
   
};
const MonthlyDataTableClient = () => {
    let data1 = sessionStorage.getItem('user');
    let clientData = JSON.parse(data1);
    let id = clientData.id;
    console.log('COMPANY Id',id);
   const[complianceFiledDate,setComplianceFiledDate]=useState('');
    const[monthlyData,setMonthlyData]=useState({});
    const [vendorId,setVendorId]=useState('');
    const[assessorOptions,setAssessorOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]); 
    const[clientFormData,setClientFormData]=useState({
        clientName: '',
        companyId: id,
        principleEmployer: '',
        companyAddress: '',
        placeName: '',
        clientContactPersonName: '',
        clientContactPersonEmailId: '',
        clientContactPersonPhoneNumber: '',
        stateId: ''
    });
   
   const  [assessorFormData,setAssessorFormData] = useState({
    assessorCompanyName:'',
    assessorId: '',
   
   });
   const  [vendorFormData,setVendorFormData] = useState({
    vendorCompanyName:'',
    vendorId: '',
   
   });
   const [showModal, setShowModal] = useState(false);  

   const handleShow = () => setShowModal(true); 
   const handleClose = () => setShowModal(false);
   const [selectedMonth, setSelectedMonth] = useState(''); 
const [selectedYear, setSelectedYear] = useState(null);
const fileInputRefs = useRef(Array(titles.length * Math.max(...Object.values(preferredSubTitles).map(arr => arr.length))).fill(null).map(() => React.createRef()));
useEffect(()=>{
    api.admin.getassessor(id) 
                            .then(response => {
                                setAssessorOptions(response.data.data);
                                console.log("assessorData",response.data.data) 
                            })
                            .catch(error => {
                                console.error('Error fetching assessor details:', error);
                            });
},[])

useEffect(()=>{
    api.client.getClient(id)
    .then(response => {
        setClientFormData(response.data.data);
        console.log("client data",response.data)
       
    })

},[])
   
const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    
   
};
useEffect(() => {

    if (vendorId && selectedMonth && selectedYear) {
      fetchVendorData(vendorId, selectedMonth, selectedYear);
    }
  }, [vendorId, selectedMonth, selectedYear]);
const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    
   
};

    
      const handleChange = (event) => {
        const { name, value } = event.target;               
        if (name === 'assessorName') {
            const selectedAssessor = assessorOptions.find(assessor => assessor.assessorCompanyName === value);
            if (selectedAssessor) {
                setAssessorFormData(prevState => ({
                    ...prevState,
                    assessorCompanyName: value,
                    assessorId: selectedAssessor.assessorId, 
                }));
                 
                 fetchVendor(selectedAssessor.assessorId);
            }
        }
        
        if (name === 'vendorName') {
            const selectedVendor = vendorOptions.find(vendor => vendor.vendorCompanyName === value);
            if (selectedVendor) {
                setVendorFormData(prevState => ({
                    ...prevState,
                    vendorCompanyName: value,
                    vendorId: selectedVendor.vendorId, 
                }));
                    setVendorId(selectedVendor.vendorId);
                    sessionStorage.setItem('selectedVendorId', selectedVendor.vendorId);

        
            }
        }
        
      
        if (name !== 'clientName' && name !== 'assessorName') {
            setClientFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


const fetchVendor = async (assessorId) => {
     console.log("id",assessorId);
    try {
        const response = await api.assessor.getVendors(assessorId); 
        const vendorDetails = response.data.data;
        setVendorOptions(vendorDetails); 
       
    } catch (error) {
        console.error("Vendor could not be fetched:", error);
    }
};
const fetchVendorData = async (vendorId,month,year) => {
    try {
        const response = await api.vendor.getMonthlyData(vendorId,month,year);
       if(response.status===200){
        const data = response.data.data || {};
        
        
        const initializedData = {};
        Object.keys(data).forEach((key) => {
            const entry = data[key];
            initializedData[key] = {
                fileUrl: entry.fileUrl || '',
                validation: entry.validation !== null ? entry.validation : 0,
                remark: entry.remark || '',
                vendorRemark: entry.vendorRemark || '',
                na:entry.na||''
            }; 
            setMonthlyData(initializedData);
            setComplianceFiledDate(data.complianceFiledDate);
        });
    }
    else if(response.status===400){
        window.alert("Vendor data not found for selected month and year");
        // setSelectedYear(null);
        // setSelectedMonth('');
        setMonthlyData({});
         setComplianceFiledDate('');

    }
        
    } catch (error) {
        console.error('Error fetching monthly data', error);
    }
};
const renderRows = () => {
    return titles.map((title, titleIndex) => (
        <React.Fragment key={titleIndex}>
            <tr style={{ backgroundColor: COLORS.bggray }}>
                <td colSpan={6}><strong className='text-dark'>{title}</strong></td>
            </tr>
            {preferredSubTitles[title].map((subTitle, subTitleIndex) => {
                const dataEntry = monthlyData[titleToKeyMap[subTitle]] || {};
                const { validation = 0, remark = '', vendorRemark = '',fileUrl = '',na=false } = dataEntry;

                const rowStyle = validation === 2 ? { backgroundColor: 'lightgreen' } :
                                 validation === 3 ? { backgroundColor: 'lightcoral' } : validation === 1 ? {backgroundColor:COLORS.bggray}:{backgroundColor:'transparent'}
                                

                return (
                    <>
                    <tr key={subTitleIndex} style={rowStyle}>
                        <td>{subTitle}
                       
                     
                           
                        </td>
                        <td>
    {fileUrl ? (
        <div className="mt-2">
            <button 
                className='btn btn-sm btn-secondary' 
                onClick={() => window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')}
            >
                <i className="bi bi-eye"></i> Doc
            </button>
            <button 
                className="btn mx-1 btn-sm btn-secondary" 
                onClick={() => window.open(fileUrl)}
            >
                <i className="bi bi-download"></i> Doc
            </button>
          
        </div>
    ) : (
        na ? (
            <span>Not Applicable</span>  
        ) : (
            <span></span>  
        )
    )}
</td>
<td>
    {validation==1?"Under Review": validation==2?"Accepted":validation===3?"rejected":""}
</td>
<td>
<div >
                                {vendorRemark && (
            <div style={{overflow:'auto',width:'200px'}}>vendor remark: {vendorRemark}</div>
        )}
        {remark && (
            <div style={{overflow:'auto',width:'200px'}}>Assessor Remark:{remark}</div>
        )}
                                </div>
</td>
   
                            
                  
                    </tr>
                   
                   
                    </>
                );
            })}
        </React.Fragment>
    ));
};
  return (
    <>

    <div className='px-2 pt-4' style={{ letterSpacing: '1px' }}>
<div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Under review</span>
  <div className="vr  mx-3"></div>
  <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Accepted</span>
  <div className="vr mx-3"></div>
  <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Rejected</span>
</div>

       <div className=' mt-3 d-flex' >
<Select 
        value={assessorFormData.assessorCompanyName}
        onChange={handleChange}
        name="assessorName"
        displayEmpty
    >
        <MenuItem value="" disabled>Select Assessor</MenuItem>
        {Array.isArray(assessorOptions) && assessorOptions.map((assessor) => (
            <MenuItem key={assessor.assessorId} value={assessor.assessorCompanyName}>
                {assessor.assessorCompanyName}, {assessor.assessorAddress}
            </MenuItem>
        ))}
    </Select>

    <Select className="mx-2"
        value={vendorFormData.vendorCompanyName}
        onChange={handleChange}
        name="vendorName"
        displayEmpty
       
    >
        <MenuItem value="" disabled>Select Vendor</MenuItem>
        {Array.isArray(vendorOptions) && vendorOptions.map((vendor) => (
            <MenuItem key={vendor.vendorId} value={vendor.vendorCompanyName}>
                {vendor.vendorCompanyName} {vendor.placeName}, {vendor.stateId}
            </MenuItem>
        ))}
    </Select>
    <select className="mx-2" value={selectedMonth || ''} onChange={handleMonthChange}>
                    <option value="">Select Month</option>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.name}
                        </option>
                    ))}
                </select>
                <select  value={selectedYear || ''} onChange={handleYearChange} >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
              

    </div>
   
            {complianceFiledDate && (
                 <div className="d-flex pt-2">
    <p className=' bg-warning p-2' style={{width:'400px'}}>
  {complianceFiledDate ? `Compliance Filed Date: ${complianceFiledDate.slice(0, 10)}` : ""}
</p>
<button className="btn btn-sm btn-dark mx-2" onClick={handleShow}>
    View Report
    </button>   
    </div>        
)}
  
   
               
             
                           
                         
  
     <table className="mt-3 fixed-header-table" border="1" >
     <thead>
         <tr >
             <th>Title</th>
             <th>Uploaded File</th>
             <th>Status</th>
             <th>Remark</th>
             
             
            
         </tr>
     </thead>
     <tbody>
         {renderRows()}
     </tbody>
    



 </table>
 {vendorId && (
 <MonthlyReport showModal={showModal} onClose={handleClose}   selectedMonth={selectedMonth} 
  selectedYear={selectedYear} />)}
 </>
  )
}

export default MonthlyDataTableClient
