import React, { useState, useEffect, useRef } from 'react';
import { COLORS } from '../Component/colors';

import api from '../Services/api';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import MonthlyReport from './MonthlyReport';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



const months = [
    { name: "JANUARY", value: "01" },
    { name: "FEBRUARY", value: "02"},
    { name: "MARCH", value: "03" },
    { name: "APRIL", value: "04" },
    { name: "MAY", value: "05" },
    { name: "JUNE", value: "06" },
    { name: "JULY", value: "07" },
    { name: "AUGUST", value: "08" },
    { name: "SEPTEMBER", value: "09" },
    { name: "OCTOBER", value: "10" },
    { name: "NOVEMBER", value: "11" },
    { name: "DECEMBER", value: "12" }
  ];
  const years = Array.from({ length: 2040 - 2020 + 1 }, (x, i) => 2020 + i);
const titles = [
    "The Contract Labour (Regulation & Abolition) Act, 1970",
    "Generic",
    "Minimum Wages Act, 1936",
     "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952",
     "The Employees' State Insurance Act and Regulations, 1948",
     "Professional Tax",
    "The Payment of Bonus Act, 1972",
    "Shops And Commercial Establishments Act",
    "The Maternity Benefit Act, 1961",
    "The Employees Compensation Act, 1923",
    "The Equal Remuneration Act, 1976", 
    "The Factories Act, 1948",
    "The Labour Welfare Fund Act, 1965",
   
];
const titleToKeyMap = {
    "Combined Muster Roll-cum-Register of Wages": "combinedMusterRollCumRegisterOfWages",
    "Register of Workmen": "RegisterOfWorkmen",    
    "Leave Register/Leave Book":"leaveRegisterLeaveBook",
    "Employment Card":"EmploymentCard",
    "Wage Slip": "WageSlip",
    "Service Certificate for Left Employees" : "ServiceCertificateForLeftEmployees",
    "In and out time Register": "inAndOutTimeRegister",
    "Bank Statement for Salary Credit Confirmation": "BankStatementOrSalaryCreditConfirmation",
    "PF Declaration on letterhead": "PFDeclarationOnLetterhead",
    "ESI Declaration on letterhead": "ESIDeclarationOnLetterhead",
    "Overtime Declaration on letterhead": "OvertimeDeclarationOnLetterhead",
    "Payment done as per revised rate of Minimum wages": "PaymentDoneAsPerRevisedRateOfMinimumwages",
    "PF Monthly ECR": "PFMonthlyECR",
    "PF Combined Challan": "PFCombinedChallan",
    "PF TRRN Summary": "PFTRRNSummary",
    "IW-1 International Worker Monthly Returns": "IW1InternationalWorkerMonthlyReturns",
    "ESI-Accident Register": "ESIAccidentRegister",
    "ESIC Contribution Challan": "ESICContributionChallan",
    "ESIC Monthly Statement": "ESICMonthlyStatement",
    "Pehchan Card (Insured Person)": "PehchanCard",
    "Professional Tax Returns": "professionalTaxReturns",
    "PT Challan": "PTChallan",
    "Monthly Bonus Paid or Not": "MonthlyBonusPaidOrNot",
    "Statutory Bonus Return": "statutoryBonusReturn",
    "Registration Of Establishment": "registrationOfEstablishment",
    "Appointment Order In Form Q": "appointmentOrderInFormQ",
    "Muster Roll Of Women Workers": "musterRollOfWomenWorkers",
    "Employees Compensation Policy": "employeesCompensationPolicy",
    "Equal Remuneration And Register To Be Maintained": "EqualRemunerationAndRegisterToBeMaintained",
    "Register of Overtime and Payment": "RegisterofOvertimeandPayment",
    "Register Of Leave And Leave Book": "RegisterOfLeaveAndLeaveBook",
    "Register Of Adult Workers Cum Muster Roll":"RegisterOfAdultWorkersCumMusterRoll",  
    "Combined register for Muster Roll and Wages":"CombinedregisterforMusterRollandWages",
    "Register Of Unclaimed Wages And Payment To The Board": "RegisterOfUnclaimedWagesAndPaymentToTheBoard",
    "LWF Challan": "LWFChallan",

    
    
     


};



const preferredSubTitles = {
    "The Contract Labour (Regulation & Abolition) Act, 1970": [
        "Combined Muster Roll-cum-Register of Wages",
        "Register of Workmen",
        "Leave Register/Leave Book",
        "Employment Card",
        "Wage Slip",
        "Service Certificate for Left Employees",
       
    ],
    "Generic": [
        "In and out time Register",
        "Bank Statement for Salary Credit Confirmation",
        "PF Declaration on letterhead",
        "ESI Declaration on letterhead",
        "Overtime Declaration on letterhead"
    ],
    "Minimum Wages Act, 1936": [
        "Payment done as per revised rate of Minimum wages"
    ],
    "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952": [
        "PF Monthly ECR",
        "PF Combined Challan",
        "PF TRRN Summary",
        "IW-1 International Worker Monthly Returns"
    ],
    "The Employees' State Insurance Act and Regulations, 1948": [
        "ESI-Accident Register",
        "ESIC Contribution Challan",
        "ESIC Monthly Statement",
        "Pehchan Card (Insured Person)"
    ],
    "Professional Tax": [
        "Professional Tax Returns",
        "PT Challan"
    ],
    "The Payment of Bonus Act, 1972": [
        "Monthly Bonus Paid or Not",
        "Statutory Bonus Return"
    ],
    "Shops And Commercial Establishments Act": [
        "Registration Of Establishment",
        "Appointment Order In Form Q"
    ],
    "The Maternity Benefit Act, 1961": [
        "Muster Roll Of Women Workers",
        
    ],
    "The Employees Compensation Act, 1923": [
        "Employees Compensation Policy",
        
    ],
    "The Equal Remuneration Act, 1976": [
        "Equal Remuneration And Register To Be Maintained",
        
    ],
    "The Factories Act, 1948": [
        "Register of Overtime and Payment",
        "Register Of Leave And Leave Book",
        "Register Of Adult Workers Cum Muster Roll",
        "Combined register for Muster Roll and Wages"
    ],
    "The Labour Welfare Fund Act, 1965": [
        "Register Of Unclaimed Wages And Payment To The Board",
        "LWF Challan"
    ],
    
   
};

const MonthlyData = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [monthlyData, setMonthlyData] = useState({});
    const [uploadStatuses, setUploadStatuses] = useState({});
    const [uploadSuccess, setUploadSuccess] = useState({});
    const [isSaved, setIsSaved] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState(null);
    const [showModal, setShowModal] = useState(false);  // State to control the modal visibility

    const handleShow = () => setShowModal(true);  // Show modal
    const handleClose = () => setShowModal(false);

    const fileInputRefs = useRef(titles.flatMap(title => 
        preferredSubTitles[title].map(() => React.createRef())
    ));
    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };
    
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
    
 

   
    

    const vendor = JSON.parse(sessionStorage.getItem('user'));
    const id = vendor?.id;

    useEffect(() => {
       
        if (id && selectedMonth !== null && selectedYear !== null) {
            fetchData(id,selectedMonth,selectedYear);
        }
    }, [id, selectedMonth, selectedYear]);


      const fetchData = async (vendorId,month,year) => {
        console.log(selectedMonth,selectedYear,id)
        try {
            const response = await api.vendor.getMonthlyData(vendorId,month,year);
            if(response.status===200){
            const data = response.data.data || {};
            let complianceDate = '';
    
            const initializedData = {};
            Object.keys(data).forEach((key) => {
                const entry = data[key];
                initializedData[key] = {
                    fileUrl: entry.fileUrl || '',
                    validation: entry.validation !== null ? entry.validation : 0,
                    remark: entry.remark || '',
                    vendorRemark: entry.vendorRemark || '',
                    file: entry.file || '', 
                    na: entry.na ||false
                    
                };
                if (data.complianceFiledDate) {
                    complianceDate = data.complianceFiledDate; 
                }
            });
    
            if (complianceDate) {
                setSelectedDate(new Date(complianceDate.split('/').reverse().join('-')));
            } else {
                setSelectedDate(null); 
            }

            setMonthlyData(initializedData);
            console.log("Initialized data:", initializedData);
        }
        else if(response.status===400){
            window.alert("Vendor data not found for selected month and year");
            setSelectedYear(null);
            setSelectedMonth('');
            setMonthlyData({});
            setSelectedDate(null); 

        }
        
        } catch (error) {
            console.error('Error fetching monthly data', error);
        }
    };
    const isDateDisabled = (date) => {
        const day = date.getDate();
        const today = new Date();
        const todayDay = today.getDate();
        const todayMonth = today.getMonth();
        const todayYear = today.getFullYear();
    
       
        // if (day >= 18 && day <= 25) {
        //     return true; 
        // }
    
      
        if (todayDay >= 1 && todayDay <= 28) {
            return day == todayDay; 
        }
    
        return false; 
    };
    
    
    const handleDateChange = (date) => {
       
        setSelectedDate(date);
    };

    const handleNaChange = (subTitle) => {
        const key = titleToKeyMap[subTitle];  
        const updatedData = { ...monthlyData };  
    
        updatedData[key] = {
            ...updatedData[key],
            na: !updatedData[key]?.na,  
        };
    
        setMonthlyData(updatedData); 
    };
    const handleFileChange = (subTitle) => (event) => {
        const files = event.target.files; 
        console.log("Files selected:", files); 
    
        const file = files[0]; 
        const key = titleToKeyMap[subTitle];
    
        const updatedData = { ...monthlyData };
    
        if (file) {
            console.log("Selected file name:", file.name); 
            updatedData[key] = {
                ...updatedData[key],
                file: file.name,
                selectedFile: file,
            };
        } else {
            console.log("No file selected.");
            updatedData[key] = {
                ...updatedData[key],
                file: '',
                selectedFile: null,
            };
        }
    
        setMonthlyData(updatedData);
    };
    
    const allDataNotUploaded = () => {
        return Object.values(monthlyData).every(entry => entry.validation === 0);
    };
    
    const handleFileUpload = async (subTitle, title) => {
        const key = titleToKeyMap[subTitle];
        const selectedFile = monthlyData[key]?.selectedFile;
    
        console.log("Selected file for upload:", selectedFile);
    
    
        if (!selectedFile) {
            alert("Please upload a file before saving.");
            return;
        }
    
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('vendorId', id);
        formData.append('fieldName', key);
    
        try {
            const response = await axios.put('https://www.grcpulse.in/api/monthlydata/uploadfile', formData);
            if (response.status === 200) {
                const updatedData = { ...monthlyData };
                updatedData[key] = {
                    ...updatedData[key],
                    validation: 0,
                    fileUrl: response.data.data,
                    remark: '',
                    vendorRemark: '',
                };
                setMonthlyData(updatedData);
                setUploadSuccess((prev) => ({
                    ...prev,
                    [subTitle]: true,
                }));
                alert('File uploaded successfully!');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file. Please try again.');
        }
    };
    
    // const handleCancel = (subTitle) => {
        
    //     const inputRef = fileInputRefs.current.find((ref) => ref.current?.files.length);
    //     if (inputRef && inputRef.current) {
    //         inputRef.current.value = ''; 
    //     }
    //     setUploadStatuses(prev => ({ ...prev, [subTitle]: 'Upload' }));
    //     setMonthlyData(monthlyData); 
    // };
    const handleRemarkChange = (subTitle, newRemark) => {
        setMonthlyData(prevData => {
            const entry = prevData[titleToKeyMap[subTitle]] || {};
            return {
                ...prevData,
                [titleToKeyMap[subTitle]]: {
                    ...entry,
                    vendorRemark: newRemark,
                }
            };
        });
    };
    const handleSave = async (subTitle) => {
        if (!selectedDate) {
            alert("Please select a compliance filing date.");
            return;
        }
        const key = titleToKeyMap[subTitle];
        const updatedEntry = monthlyData[key] || {};

        if (!updatedEntry.selectedFile && !updatedEntry.na) {
            alert("Please upload a file or mark as 'Not Applicable'.");
            return;
        }
        const Monthlydata = {
            vendorId: id,
            month:selectedMonth,
            year:selectedYear,
            // objectivesOfTheAudit :" ",
            // auditorObservation :" ",
            // auditorRecommendation :"",
            
            complianceFiledDate: selectedDate,
            [key]: {
                fileUrl: updatedEntry.fileUrl,
                validation: 1,
                remark: updatedEntry.remark,
                vendorRemark: updatedEntry.vendorRemark,
                na : updatedEntry.na
            }
        };
    
        try {
            const response = await axios.put('https://www.grcpulse.in/api/monthlydata/update', Monthlydata);
            if (response.status === 200) {
                 setMonthlyData(prevData => ({
                    ...prevData,
                    [key]: {
                        ...prevData[key],
                        fileUrl: updatedEntry.fileUrl,
                        validation: 1,
                        remark: updatedEntry.remark,
                        vendorRemark: updatedEntry.vendorRemark,
                        background: COLORS.bggray,
                        na:updatedEntry.na

                    }
                }));
                setIsSaved(true);
                alert("Updated");
                
            }
            else if(response.status === 400){
                window.alert(response.data.message)
            }
        } catch (error) {
            console.error('Error saving monthly data:', error);
        }
    };
    
console.log(monthlyData);
const renderRows = () => {
    return titles.map((title, titleIndex) => (
        <React.Fragment key={titleIndex}>
            <tr style={{ backgroundColor: COLORS.bggray}}>
                <td colSpan={6} ><strong className='text-dark'>{title}</strong></td>
            </tr>
            {preferredSubTitles[title].map((subTitle, subTitleIndex) => {
               const titleIndex = titles.indexOf(title); // Get the index of the title
               const fileIndex = titleIndex * preferredSubTitles[title].length + subTitleIndex; 
                const dataEntry = monthlyData[titleToKeyMap[subTitle]] || {};
                const { validation = 0, remark = '',vendorRemark='', fileUrl = '', na = false,complianceFiledDate='' } = dataEntry;
               

                const statusText = validation === 0 ? 'Not Uploaded' :
                                   validation === 1 ? 'Under Review' :
                                   validation === 2 ? 'Accepted' : 'Rejected';
                              const isSaveEnabled = dataEntry.selectedFile || na;    
                                  
                return (
                    <tr key={subTitleIndex} style={{ backgroundColor: validation === 1 ? COLORS.bggray : validation === 2 ? 'lightgreen' : validation === 3 ? 'lightcoral': 'transparent' }}>
                        <td>{subTitle}</td>
                        <td className="d-flex justify-content-between">
                           <div className="d-grid">
                                <input
                                    type="file"
                                    ref={fileInputRefs.current[fileIndex]}
                                    onChange={handleFileChange(subTitle)}
                                    disabled={na}
                                />
                                 <button className="mt-2 btn btn-sm btn-light" style={{border:'1px solid gray'}}  disabled={na} onClick={() => handleFileUpload(subTitle,title)}>
                                {uploadStatuses[subTitle] || 'Upload'}
                            </button>
                               
                            {fileUrl && (
                        <div className="mt-2">
                  <button className='btn btn-sm btn-secondary' onClick={() => window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')}><i className="bi bi-eye"></i> Doc</button>
                  <button className="btn mx-1 btn-sm btn-secondary" onClick={() => window.open(fileUrl)}><i className="bi bi-download"></i> Doc</button>
                </div>
                        )}
                        </div>
                       
                        <div className="m-2 d-flex">
                     
                        <input
    type="checkbox"
    checked={na}  
    onChange={() => handleNaChange(subTitle)}  
/>
<p className='pt-3'> Not Applicable</p>
</div>          
                       
                        </td>
                    <td>
                    <textarea
                                 value={vendorRemark}  
                                    placeholder="Enter your remarks here"
                                 onChange={(e) => handleRemarkChange(subTitle, e.target.value)}
                                    rows="2"
                                    style={{ width: '100%' }}
                                />
                    </td>

                        <td>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <button className="btn btn-md btn-success" onClick={() => handleSave(subTitle)} disabled={!isSaveEnabled} >Save</button>
                                {/* <button className='btn btn-md btn-light mx-2' style={{border:'1px solid gray'}} onClick={()=>handleCancel(subTitle)} >Cancel</button> */}
                            </div>
                        </td>
                        <td><tr>{statusText} </tr>
                        {remark && (
            <div style={{overflow:'auto',width:'200px'}}>Remark: {remark}</div>
        )}
                        </td>
                        
{/*                       
                        
                        <td>{remarkUpdate && <p>{remarkUpdate}</p>}</td> */}
                    </tr>
                    
                );
            })}
        </React.Fragment>
    ));
};


    
    
    
    
    
    

    return (
        <div>
            <div className='d-flex' style={{justifyContent:'space-between'}}>
            <div className="mt-4" style={{ letterSpacing: '1px'}}>
        <div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', marginLeft:'8px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Under review</span>
          <div className="vr  mx-3"></div>
          <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Accepted</span>
          <div className="vr mx-3"></div>
          <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Rejected</span>
        </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end',marginBottom: '10px' ,marginTop:"10px"}}>
                <select value={selectedMonth || ''} onChange={handleMonthChange} style={{ marginRight: '10px',marginTop:"12px" }}>
                    <option value="">Select Month</option>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.name}
                        </option>
                    ))}
                </select>
                <select value={selectedYear || ''} onChange={handleYearChange} style={{marginTop:'12px'}}>
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
            </div>
            {!selectedDate && !isSaved ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
                   
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        filterDate={isDateDisabled}
                        placeholderText="Compliance filing date"
                        dateFormat="dd/MM/yyyy"
                    />
                    
                </div>
            ) : (
                <div className="float-end" style={{ display: 'flex', justifyContent: 'space-between' }}>
                       <div className="mt-1">
                       <button className="btn btn-sm btn-dark mx-2" onClick={handleShow}>
      <span className="text-decoration-none text-white">View Report</span>
    </button>
                </div>
                <div>
                <p className='bg-warning p-2' >Compliance Filed Date : {selectedDate ? selectedDate.toLocaleDateString() : 'Not Set'}</p>
                </div>  
             
                </div>
            )}
            <table border="1" >
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Upload File</th>
                        <th>VendorRemark</th>
                        <th>Actions</th>
                        <th>AssessorRemark</th>
                      
                    </tr>
                </thead>
                <tbody>
                   {renderRows()}
                </tbody>
            </table>
        
           <MonthlyReport showModal={showModal} onClose={handleClose}  selectedMonth={selectedMonth} 
  selectedYear={selectedYear} />
           </div>
    );
}

export default MonthlyData;
