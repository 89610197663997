import React, { useState } from 'react';
import { Container, Grid, Card, CardContent, TextField, Button, Modal, Typography, Divider } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import VendorAppBar from '../Component/VendorAppBar';

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string()
    .required('New Password is required')
    .min(6, 'Password must be at least 6 characters'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm New Password is required'),
});

const ChangePasswordForm = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      console.log(values);
      // Show modal on successful password change
      toggleModal();
    },
  });

  return (
    <>
      <Container>
        <Grid container justifyContent="center" mt={5}>
          <Grid item md={6}>
            <Card sx={{ borderRadius: '25px', boxShadow:'8px 8px 8px 8px rgba(250,0,0,0.2)'}}>
              <CardContent>
                <Typography variant="h5" component="h3">Change Password</Typography>
                <Divider sx={{ my: 2 }} /> 
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    type="password"
                    id="oldPassword"
                    name="oldPassword"
                    label="Old Password"
                    value={formik.values.oldPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                    helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                    sx={{ marginBottom: '20px' }}
                  />
                  <TextField
                    fullWidth
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    label="New Password"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                    sx={{ marginBottom: '20px' }}
                  />
                  <TextField
                    fullWidth
                    type="password"
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    value={formik.values.confirmNewPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                    helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                    sx={{ marginBottom: '20px' }}
                  />
             <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className='btn btn-danger' type="submit" style={{width:'100%'}}>Save</button>
                 </div>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Modal after changing pass */}
      <Modal open={modalOpen} onClose={toggleModal} style={{ borderRadius: '25px' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '25px', outline: 'none' }}>
          <Typography variant="h6" component="h2">Password Changed</Typography>
          <Typography variant="body1" component="p">Password successfully changed.</Typography>
          <button className='btn btn-danger' onClick={toggleModal}>Back to login</button>
        </div>
      </Modal>
    </>
  );
}

export default ChangePasswordForm;
