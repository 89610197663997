export const getUserSpecificHeaders = () => {  
	let token = sessionStorage.getItem('Token');

	if (!token) {
		return null;
	}
	
	return ({		
		Authorization: `Bearer ${token}`,
	});
};