
import React, { useState,useEffect } from 'react';
import './EditableCard.css';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import axios from 'axios';
import api from '../Services/api';
import {COLORS} from './colors';

const MasterdataTableClient = () => {
    let data1 = sessionStorage.getItem('user');
    let clientData = JSON.parse(data1);
    let id = clientData.id;
    console.log('COMPANY Id',id);
    const [vendorData, setVendorData] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const[assessorOptions,setAssessorOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]); 
    const[clientFormData,setClientFormData]=useState({
        clientName: '',
        companyId: id,
        principleEmployer: '',
        companyAddress: '',
        placeName: '',
        clientContactPersonName: '',
        clientContactPersonEmailId: '',
        clientContactPersonPhoneNumber: '',
        stateId: ''
    });

   const  [assessorFormData,setAssessorFormData] = useState({
    assessorCompanyName:'',
    assessorId: '',
   
   });
   const  [vendorFormData,setVendorFormData] = useState({
    vendorCompanyName:'',
    vendorId: '',
   
   });

useEffect(()=>{
    api.admin.getassessor(id) 
                            .then(response => {
                                setAssessorOptions(response.data.data);
                                console.log("assessorData",response.data.data) 
                            })
                            .catch(error => {
                                console.error('Error fetching assessor details:', error);
                            });
},[])

useEffect(()=>{
    api.client.getClient(id)
    .then(response => {
        setClientFormData(response.data.data);
        console.log("client data",response.data)
       
    })

},[])
   
    

    
      const handleChange = (event) => {
        const { name, value } = event.target;               
        if (name === 'assessorName') {
            const selectedAssessor = assessorOptions.find(assessor => assessor.assessorCompanyName === value);
            if (selectedAssessor) {
                setAssessorFormData(prevState => ({
                    ...prevState,
                    assessorCompanyName: value,
                    assessorId: selectedAssessor.assessorId, 
                }));

                fetchVendor(selectedAssessor.assessorId);
            }
        }
        
        if (name === 'vendorName') {
            const selectedVendor = vendorOptions.find(vendor => vendor.vendorCompanyName === value);
            if (selectedVendor) {
                setVendorFormData(prevState => ({
                    ...prevState,
                    vendorCompanyName: value,
                    vendorId: selectedVendor.vendorId, 
                }));

                fetchVendorData(selectedVendor.vendorId);
            }
        }
        
      
        if (name !== 'clientName' && name !== 'assessorName') {
            setClientFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


const fetchVendor = async (assessorId) => {
     console.log("id",assessorId);
    try {
        const response = await api.assessor.getVendors(assessorId); 
        const vendorDetails = response.data.data;
        setVendorOptions(vendorDetails); 
       
    } catch (error) {
        console.error("Vendor could not be fetched:", error);
    }
};
 
  const fetchVendorData = async (vendorId) => {
     
    try {
      const response = await axios.get(`https://www.grcpulse.in/api/companyMasterData/vendorDetails/${vendorId}`);
      setVendorData(response.data.data);
      const newCompletedCheckboxes = [];
      const newRejectedCheckboxes = [];
      const newRemarks = [];
 
      // Iterate over the fetched data and set the values accordingly
      for (let i = 0; i < titles.length; i++) {
       
        const rowData = response.data.data[Object.keys(response.data.data)[i]];
       
        if (rowData) {
          if (rowData.validation === 2) {
            newCompletedCheckboxes[i] = true;
            newRejectedCheckboxes[i] = false;
            newRemarks[i] = rowData.remark;
           
           
          } else if (rowData.validation === 3) {
            newCompletedCheckboxes[i] = false;
            newRejectedCheckboxes[i] = true;
            newRemarks[i] = rowData.remark;
           
           
          }else if(rowData.validation === 1) {
            newCompletedCheckboxes[i] = false;
            newRejectedCheckboxes[i] = false;
            newRemarks[i] = " ";
            
          }
          else {
            newCompletedCheckboxes[i] = false;
            newRejectedCheckboxes[i] = false;
            newRemarks[i] = " ";
          }
          
        } 
      }
     
      setCompletedCheckboxes(newCompletedCheckboxes);
      setRejectedCheckboxes(newRejectedCheckboxes);
      setRemarks(newRemarks);  
     
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };
 
 
 
    const titles = [
        "Date of Commencement",
        "Date of Completion",
        "PO Copy Number",
        "EPFO Allotment copy",
        "ESIC allotment copy",
        "P Tax RC Number",
        "PT EC Number",
        "LWF Number",
        "Factory Licence",
        "Building plan layout",
        "Stability Certificate",
        "Concern to Establishment",
        "Concern to Operations",
        "Fire Advisory",
        "Fire NOC",
        "Environment Clearance",
        "Medical/Hospital - Certificate",
        "Apprenticeship Registration",
        "NEEM/NAPS - Registration",
        "CLRA RC",
        "S&CE RC",
        "CLRA Licence",
        "BOCW RC",
        "ISMW RC",
        "ISMW Licence",
        "PASSARA Licence",
        "FSSAI Licence"
    ];
    const [completedCheckboxes, setCompletedCheckboxes] = useState(new Array(titles.length).fill(false));
    const [rejectedCheckboxes, setRejectedCheckboxes] = useState(new Array(titles.length).fill(false));
    const [remarks, setRemarks] = useState(new Array(titles.length).fill(''));
    const [savedStatus, setSavedStatus] = useState(new Array(titles.length).fill(false));
    

   
    //   setSavedStatus(prevStatus => {
    //     const newStatus = [...prevStatus];
    //     newStatus[index] = false;
    //     return newStatus;
    // });
    //     if (column === 'accepted') {
    //         const newCompletedCheckboxes = [...completedCheckboxes];
    //         newCompletedCheckboxes[index] = !newCompletedCheckboxes[index];
    //         // If completed checkbox is clicked, disable the rejected checkbox
    //         if (newCompletedCheckboxes[index]) {
    //             const newRejectedCheckboxes = [...rejectedCheckboxes];
    //             newRejectedCheckboxes[index] = false;
    //             setRejectedCheckboxes(newRejectedCheckboxes);
    //         }
    //         setCompletedCheckboxes(newCompletedCheckboxes);
    //     } else if (column === 'rejected') {
    //         const newRejectedCheckboxes = [...rejectedCheckboxes];
    //         newRejectedCheckboxes[index] = !newRejectedCheckboxes[index];
    //         // If rejected checkbox is clicked, disable the completed checkbox
    //         if (newRejectedCheckboxes[index]) {
    //             const newCompletedCheckboxes = [...completedCheckboxes];
    //             newCompletedCheckboxes[index] = false;
    //             setCompletedCheckboxes(newCompletedCheckboxes);
    //         }
    //         setRejectedCheckboxes(newRejectedCheckboxes);
    //     }
    // };
    // const handleRemarkChange = (index, value) => {
    //     const newRemarks = [...remarks];
    //     newRemarks[index] = value;
    //     setRemarks(newRemarks);
    //     setSavedStatus(prevStatus => {
    //       const newStatus = [...prevStatus];
    //       newStatus[index] = false;
    //       return newStatus;
    //   });
 
    // };
   
 const handleViewDocument = (fileUrl) => {
window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')
  };
 
  
 
    const rows = titles.map((title, index) => {
      let rowClassName = '';
      if (vendorData && vendorData[Object.keys(vendorData)[index]]) {
          const validationStatus = vendorData[Object.keys(vendorData)[index]].validation;
          if (validationStatus === 1) {
              rowClassName = 'pending';
          } else if (validationStatus === 2) {
              rowClassName = 'accepted';
          } else if (validationStatus === 3) {
              rowClassName = 'rejected';
          }
      }
      if (completedCheckboxes[index]) {
          rowClassName = 'accepted';
      } else if (rejectedCheckboxes[index]) {
          rowClassName = 'rejected';
      }
      let filename = '';
      if (
        vendorData &&
        vendorData[Object.keys(vendorData)[index]] &&
        vendorData[Object.keys(vendorData)[index]].fileUrl
      ) {
        const fullUrl = vendorData[Object.keys(vendorData)[index]].fileUrl;
        filename = fullUrl.substring(fullUrl.lastIndexOf('/') + 1);
      }

      return (
        <tr className={rowClassName} >
                    <td>{title}</td>
                    <td>
                    {vendorData && (
                        <div style={{overflowX:'auto',overflowY:'hidden',width:'200px',height:'50px'}}>
                           
                            <p >{vendorData[Object.keys(vendorData)[index]].value}</p>
                        </div>
                    )}
                </td>
                    <td>   {vendorData && (
                        <>
                           
                           {filename && (
        <div >
         <button className='btn btn-sm btn-primary' onClick={() => handleViewDocument(vendorData[Object.keys(vendorData)[index]].fileUrl)}><i class="bi bi-eye"></i> Doc</button>
         <button className="btn mx-1 btn-sm  btn-primary" onClick={()=>window.open(vendorData[Object.keys(vendorData)[index]].fileUrl)}><i class="bi bi-download"></i> Doc</button>
        </div>
       
      )}
                        </>
                    )}</td>
                    <td>
    {vendorData && (
        <>
            <p>
                {vendorData[Object.keys(vendorData)[index]].validation === 1
                    ? "Pending"
                    : vendorData[Object.keys(vendorData)[index]].validation === 2
                    ? "Accepted"
                    : vendorData[Object.keys(vendorData)[index]].validation === 3
                    ? "Rejected"
                    : "Not Uploaded"} 
            </p>
        </>
    )}
</td>
                   
                   
                    <td className="remark">
                    <textarea
                        required={rejectedCheckboxes[index]}
                        value={remarks[index]}
                        readOnly
                        // onChange={e => handleRemarkChange(index, e.target.value)}
                    ></textarea>
                </td>
     
                
                </tr>
            );
        });
       
  return (
    <div>
         
         
    <section className="mt-2" style={{display:'flex',justifyContent:'space-between '}}>
    <div className='px-2 pt-4' style={{ letterSpacing: '1px' }}>
    {/* <p className=' text-center'>Principle Employer : <span style={{fontWeight:'bolder'}}>{clientFormData.principleEmployer}</span></p> */}
<div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Under review</span>
  <div className="vr  mx-3"></div>
  <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Accepted</span>
  <div className="vr mx-3"></div>
  <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Rejected</span>
</div>
        <div className='mx-2 text-center justify-content-center'>
<Select
        value={assessorFormData.assessorCompanyName}
        onChange={handleChange}
        name="assessorName"
        displayEmpty
    >
        <MenuItem value="" disabled>Select Assessor</MenuItem>
        {Array.isArray(assessorOptions) && assessorOptions.map((assessor) => (
            <MenuItem key={assessor.assessorId} value={assessor.assessorCompanyName}>
                {assessor.assessorCompanyName} {assessor.assessorAddress}
            </MenuItem>
        ))}
    </Select>

    <Select
        value={vendorFormData.vendorCompanyName}
        onChange={handleChange}
        name="vendorName"
        displayEmpty
        style={{margin:'10px'}}
    >
        <MenuItem value="" disabled>Select Vendor</MenuItem>
        {Array.isArray(vendorOptions) && vendorOptions.map((vendor) => (
            <MenuItem key={vendor.vendorId} value={vendor.vendorCompanyName}>
                {vendor.vendorCompanyName} {vendor.vendorCompanyAddress}
            </MenuItem>
        ))}
    </Select>
   

    </div>
 
    </section>
 
    <table className="fixed-header-table">
        <thead>
            <tr>
                <th>Name of DOC</th>
                <th>Date/Doc No</th>
                <th>Uploaded File</th>
               
                <th>Status</th>
                <th>Remark</th>
              
            </tr>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </table>

</div>
  )
}

export default MasterdataTableClient
