import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppBarSelector from './Pages/AppBarSelector';
import { VendorList } from './Pages/VendorList';
import VendorProfile from './Pages/VendorProfile';
import ChangePass from './Pages/ChangePass';
import MasterData from './Component/MasterData';
import Forgot from './Pages/Forgot';
import Login from './Pages/Login';
import MasterdataTableAssessor from '../src/Component/MasterdataTableAssessor';
import AdminClient from './Pages/AdminClient';
import AsessorVendor from './Pages/AssessorVendor';
import Company from './Pages/Company';
import AdminAssessor from './Pages/AdminAssessor';
import Clientdashboard from './Pages/Dashboard/Client';
import Vendordashboard from './Pages/Dashboard/Vendor';
import Admindashboard from './Pages/Dashboard/Admin';
import AssesorDashboard from './Pages/Dashboard/Assessor';
import { AssessorProfile } from './Pages/AssessorProfile';
import MasterdataTableAdmin from './Component/MasterdataTableAdmin';
import { AssessorList } from './Pages/AssessorList';
import { ClientList } from './Pages/ClientList';
import { ClientProfile } from './Pages/ClientProfile';
import MasterdataTableClient from './Component/MasterdataTableClient';
import MonthlyData from './Component/MonthlyData';
import MonthlyData1 from './Component/MonthlyDataTableClient';
import MonthlyDataTableAssessor from './Component/MonthlyDataTableAssessor';
import MonthlyDataTableClient from './Component/MonthlyDataTableClient';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    
      <AppBarSelector>
        
        <Routes>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/createCompany" element={<AdminClient />} />
          <Route path="/createVendor" element={<AsessorVendor />} />
          <Route path="/createAssessor" element={<AdminAssessor />} />
          <Route path="/profile/vendor" element={<VendorProfile/>} />
          <Route path="/profile/assessor" element={<AssessorProfile/>}/>
          <Route path="/company" element={<Company />} />
          <Route path="/changePass" element={<ChangePass />} />
          <Route path="/clientdashboard" element={<Clientdashboard />} />
          <Route path="/vendordashboard" element={<Vendordashboard />} />
          <Route path="/admindashboard" element={<Admindashboard />} />
          <Route path="/assessordashboard" element={<AssesorDashboard />} />
          <Route path="/vendorMasterData" element={<MasterdataTableAssessor />} />
          <Route path="/vendorsMasterData" element={<MasterdataTableAdmin/>}/>
          <Route path="/MasterData--vendors" element={<MasterdataTableClient/>}/>
          <Route path="/forgotPassword" element={<Forgot />} />
          <Route path="/masterdata" element={<MasterData />} />
          <Route path="/vendorList" element={<VendorList/>}/>
          <Route path="/clientList" element={<ClientList/>}/>
          <Route path="/client/profile" element={<ClientProfile/>}/>
          <Route path="/assessorList" element={<AssessorList/>}/>
          <Route path="/MonthlyData" element={<MonthlyData/>}/>
          <Route path="/vendorMonthlyData" element={<MonthlyDataTableAssessor/>}/>
          <Route path="/MonthlyData--Vendors" element={<MonthlyDataTableClient/>}/>
       </Routes>
      </AppBarSelector>
  
  );
};

export default App;
